import * as Sentry from '@sentry/nextjs';
import _ from 'lodash';
import { cast, flow, types } from 'mobx-state-tree';

import {
  APP_CONTENT_SELECTOR,
  APP_TIP_SELECTOR,
  UI_CONTENT_SELECTOR,
  VERIFY_RECAPTCHA_TOKEN_SELECTOR,
} from '@/graphql/selectors';
import staticLinks from '@/mock-data/meganav-data.json';
import { AUTH_MODAL_OPEN } from '@/shared/constant';
import {
  getMegaNavCategories,
  getMenuBuilderDataV2,
  getNavbarData,
} from '@/utils/api-helpers';
import { GQL_QUERY_OPTIONS } from '@/utils/constants';

import type { MessageRtoModelType } from '../api';
import {
  AppTipRecordModel,
  ContentSubType,
  ContentType,
  FilterRecordModel,
  MenuHierarchyRecordModel,
  MenuSortOptionRecordModel,
  MessageRtoModel,
  NavbarRecordModel,
  UiContentRecordModel,
} from '../api';
import { withRootStore } from '../helpers/with-root-store';

export const GeneralStoreModel = types
  .model('General')
  .props({
    MOV: parseInt(process.env.NEXT_PUBLIC_MOV || '49'),
    SMOV: parseInt(process.env.NEXT_PUBLIC_SMOV || '99'),
    freeShippingThreshold: parseInt(process.env.NEXT_PUBLIC_SMOV || '99'),
    notification: types.maybe(MessageRtoModel),
    topBannerOpen: true,
    dataVersion: types.optional(types.string, '0.0.0'),
    authModalOpen: false,
    navLinks: types.union(
      types.undefined,
      types.late(() => NavbarRecordModel),
    ),
    categories: types.array(types.late(() => MenuHierarchyRecordModel)),
    searchFilter: types.union(
      types.undefined,
      types.late(() => FilterRecordModel),
    ),
    searchSortOptions: types.union(
      types.undefined,
      types.array(types.late(() => MenuSortOptionRecordModel)),
    ),
    tips: types.optional(types.array(types.late(() => AppTipRecordModel)), []),
    contents: types.optional(
      types.array(types.late(() => UiContentRecordModel)),
      [],
    ),
    ready: false,
    chatReady: false,
    chatOpen: false,
    searchFilterLoaded: false,
  })
  .extend(withRootStore)
  .actions((self: any) => ({
    setSearchFilterLoaded(loaded: boolean) {
      self.searchFilterLoaded = loaded;
    },
    setReady: (ready: boolean) => {
      self.ready = ready;
    },
    setChatReady: (chatReady: boolean) => {
      self.chatReady = chatReady;
    },
    setChatOpen: (chatOpen: boolean) => {
      self.chatOpen = chatOpen;
    },
    createNotification(message: MessageRtoModelType) {
      const data = {
        ...message,
        uuid: new Date().getTime().toString(),
      };
      self.notification = cast(data);
    },
    removeNotification() {
      self.notification = undefined;
    },
    setTopBannerOpen(active: boolean) {
      self.topBannerOpen = active;
    },
    setSMOV(value: number) {
      if (value) {
        self.SMOV = value;
      }
    },
    setFreeShippingThreshold(value: number) {
      if (value) {
        self.freeShippingThreshold = value;
      }
    },
    verifyToken: flow(function* (token: string) {
      // token = token + "7623"
      try {
        const { verifyRecaptchaToken } =
          yield self.rootStore.api.mutateVerifyRecaptchaToken(
            { token },
            VERIFY_RECAPTCHA_TOKEN_SELECTOR,
          ).promise;
        return verifyRecaptchaToken;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    }),
    getAppContent: flow(function* (
      type: ContentType,
      subType?: ContentSubType,
    ) {
      try {
        const { appContent } = yield self.rootStore.api.queryAppContent(
          { type, subType },
          APP_CONTENT_SELECTOR,
          GQL_QUERY_OPTIONS,
        ).promise;
        const content = _.get(appContent, '[0].meta');
        return content;
      } catch (error) {
        Sentry.captureException(error);
      }
    }),
    getDataVersion: flow(function* () {
      const content = yield self.getAppContent(
        ContentType.CONFIG,
        ContentSubType.DATA_VERSION,
      );
      const dataVersion = content?.version;
      if (dataVersion && self.dataVersion !== dataVersion) {
        self.dataVersion = dataVersion;
      }
      return dataVersion;
    }),
    checkDataVersion: flow(function* () {
      const currentVersion = self.dataVersion;
      const latestVersion = yield self.getDataVersion();
      if (currentVersion !== latestVersion) {
        self.rootStore.productStore.getProducts();
        self.rootStore.productStore.getProductRestrictionLimits();
        return true;
      }
      return false;
    }),
    getTips: flow(function* () {
      const { allAppTipsCms } = yield self.rootStore.api.queryAllAppTipsCms(
        { first: 100 },
        APP_TIP_SELECTOR,
        GQL_QUERY_OPTIONS,
      ).promise;
      self.tips = cast(allAppTipsCms);
    }),
    getUiContents: flow(function* () {
      const { allUiContentsCms } =
        yield self.rootStore.api.queryAllUiContentsCms(
          { first: 100 },
          UI_CONTENT_SELECTOR,
          GQL_QUERY_OPTIONS,
        ).promise;
      self.contents = cast(allUiContentsCms);
    }),
    setAuthModalOpen(open: boolean) {
      self.authModalOpen = open;
      open
        ? localStorage.setItem(AUTH_MODAL_OPEN, 'true')
        : localStorage.removeItem(AUTH_MODAL_OPEN);
    },
    getMegaNavData: flow(function* () {
      try {
        const allMenuHierarchiesCms = yield getMegaNavCategories(
          self.rootStore,
        );
        const navLinks = yield getNavbarData(self.rootStore);
        self.categories = cast(allMenuHierarchiesCms);
        self.navLinks = navLinks;
      } catch (error) {
        self.navLinks = { links: staticLinks.navLinks };
        Sentry.captureException(error);
      }
    }),
    getMenuBuilderData: flow(function* () {
      try {
        const { searchFilterCmsData, sortMenuCms } = yield getMenuBuilderDataV2(
          self.rootStore,
          ['searchFilterCmsData', 'sortMenuCms'],
        );
        self.searchFilter = cast(searchFilterCmsData);
        self.searchSortOptions = cast(sortMenuCms);
      } catch (error) {
        Sentry.captureException(error);
      }
    }),
  }))
  .views((self) => ({
    get getMOV(): number {
      // TO BE UPDATED - Call API
      return 49;
    },
    get chatLoading() {
      return self.chatOpen && !self.chatReady;
    },
    getTipByKey(key: string) {
      const tip = _.find(self.tips, { key });
      return tip?.tip;
    },
    getContentByKey(key: string) {
      const content = _.find(self.contents, { key });
      return content?.content;
    },
  }));
