import 'react-responsive-carousel/lib/styles/carousel.min.css';

import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { Carousel } from 'react-responsive-carousel';

import CloseIcon from '@/components/icons/CloseIcon';
import { TOPLINE_BANNER_SELECTOR } from '@/graphql/selectors/cms';
import type { ToplineBannerV2RecordModelType } from '@/models/api';
import { useQuery } from '@/models/reactUtils';
import { useStore } from '@/models/root-store';
import { Mode } from '@/utils/constants';

import TopLineStructuredText from '../StructuredText/TopLineStructuredText/TopLineStructuredText';

export const ToplineBanner: React.FC = observer(() => {
  const {
    generalStore: { topBannerOpen, setTopBannerOpen },
  } = useStore();
  const router = useRouter();

  // check for a list of routes to not show the banner on
  const routesToNotShowBanner = [
    '/checkout',
    '/maintenance',
    '/account-delete',
    '/print-invoice',
    '/email-update',
    '/sunday-drop',
  ];
  const modesToNotShowBanner = [Mode.EDIT_CART, Mode.EDIT_SUBSCRIPTION];
  const currentRoute = router.pathname;
  const isRouteToNotShowBanner = routesToNotShowBanner.includes(currentRoute);
  const isModeToNotShowBanner = modesToNotShowBanner.includes(
    router.query.mode as Mode,
  );

  // we need to query to get the text for the topline banner from the CMS
  const { data, loading } = useQuery((store) =>
    store.api.queryAllToplineBannerV2sCms(
      { filter: { active: { eq: true } } },
      TOPLINE_BANNER_SELECTOR,
    ),
  );

  const toplineBannerCms = useMemo(() => {
    return _.get(data, 'allToplineBannerV2sCms', []);
  }, [data]);

  const intervalTime =
    toplineBannerCms &&
    toplineBannerCms.length > 0 &&
    toplineBannerCms.reduce((acc: number, curr: any) => {
      return Math.max(acc, curr.displayTime);
    }, 0);

  if (
    topBannerOpen === false ||
    isRouteToNotShowBanner ||
    isModeToNotShowBanner
  ) {
    return null;
  }

  const isLoaded = !loading && data;
  return (
    <div
      className={`relative flex h-10 justify-center bg-lime p-2`}
      id={'ToplineBanner'}
    >
      {isLoaded && toplineBannerCms?.length === 0 && (
        <div>
          <p className="font-interMedium text-xs leading-6 text-black lg:text-sm">
            Easy Weight Loss Meal Plans:{' '}
            <Link href={'/weight-loss'} legacyBehavior>
              <span className="cursor-pointer underline">Start now</span>
            </Link>
          </p>
        </div>
      )}
      {isLoaded && toplineBannerCms?.length > 0 && (
        <Carousel
          axis="vertical"
          autoPlay={true}
          emulateTouch={false}
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
          interval={intervalTime || undefined}
          swipeable={false}
          infiniteLoop
          stopOnHover
          renderArrowPrev={() => null}
          renderArrowNext={() => null}
        >
          {toplineBannerCms.map(
            (banner: ToplineBannerV2RecordModelType, idx: number) => (
              <div className="" key={idx}>
                <TopLineStructuredText content={banner.text} />
              </div>
            ),
          )}
        </Carousel>
      )}

      <div className="absolute right-4 cursor-pointer">
        <CloseIcon
          fill="rhubarb-dark"
          onClick={() => setTopBannerOpen(false)}
        />
      </div>
    </div>
  );
});
