import { onPatch } from 'mobx-state-tree';
import * as _ from 'lodash';
import type { ProductFilter } from '@/models/product/filters';
import { Cart } from '../cart/cart';

const FILTER_OBSERVER_ATTRIBUTES = ['sort', 'selectedFilters'];
const CART_OBSERVER_ATTRIBUTES = [
  'plans',
  'giftCertificates',
  'billingAddress',
  'frequency',
  'shippingCostBase',
  'delivery',
];

export function onPatchFilter(filter: ProductFilter, callback?: any) {
	return onPatch(filter, (patch) => {
		if (patch.path) {
			const attribute = _.nth(_.split(patch.path, '/'), 1);

			if (attribute && FILTER_OBSERVER_ATTRIBUTES.includes(attribute)) {
				callback();
			}
		}
	});
}

export function onPatchCart(cart: Cart, callback?: any) {
  return onPatch(cart, (patch) => {
    if (patch.path) {
      const attribute = _.nth(_.split(patch.path, '/'), 1);
      if (attribute && CART_OBSERVER_ATTRIBUTES.includes(attribute)) {
        callback();
      }
    }
  });
}
