import { cast, types, Instance } from 'mobx-state-tree';

import { Payment, PaymentModel } from './payment';
import { Address, AddressModel } from './address';
import { AttributeModel, AuthRole, ProfileStageRecordModel } from '../api';
import { ReasonsModel } from './cancelReasons';
import _ from 'lodash';
import { checkProfileStageSkipUntil } from '@/utils/helpers';

export const CustomerModel = types
  .model('Customer')
  .props({
    _id: types.identifier,
    email: types.union(types.undefined, types.null, types.string),
    firstName: types.union(types.undefined, types.null, types.string),
    lastName: types.union(types.undefined, types.null, types.string),
    role: types.enumeration(Object.values(AuthRole)),
    phone: types.maybe(types.string),
    credit: types.optional(types.number, 0),
    referCode: types.union(types.undefined, types.null, types.string),
    addresses: types.optional(types.array(AddressModel), []),
    paymentMethods: types.optional(types.array(PaymentModel), []),
    numberOfOrders: types.optional(types.number, 0),
    profileStage: types.optional(types.number, 0),
    isSubscriber: types.union(types.boolean, types.undefined, types.null),
    unsubscribeEmailStatus: types.union(
      types.undefined,
      types.null,
      types.boolean
    ),
    attributes: types.array(AttributeModel),
    productLikes: types.union(
      types.undefined,
      types.null,
      types.array(types.string)
    ),
    createdAt: types.union(types.undefined, types.frozen()),
    daysSinceLastOrder: types.union(types.undefined, types.number, types.null),
    profileForm: types.union(
      types.undefined,
      types.null,
      types.model('profileFormModel').props({
        title: types.maybe(types.string),
        uri: types.maybe(types.string),
        htmlTitle: types.maybe(types.string),
        htmlBody: types.maybe(types.string),
        htmlAction: types.maybe(types.string),
        typeformId: types.maybe(types.string),
        completedProfileStage: types.maybe(types.string),
      })
    ),
    profileStageCms: types.union(
      types.undefined,
      types.null,
      ProfileStageRecordModel
    ),
    isProfileStageNotSkipped: types.optional(types.boolean, false),
    promotionalCampaign: types.union(types.undefined, types.null, types.frozen()),
  })
  .views((self) => ({
    get payments() {
      return self.paymentMethods;
    },
    get defaultAddress(): Address | undefined {
      return self.addresses.find((address) => address.preferred);
    },
    get defaultPayment(): Payment | undefined {
      return this.payments.find((payment) => payment.preferred);
    },
    get isNewCustomer() {
      return !self.numberOfOrders;
    },
    get profileStages(): string[] {
      return (
        self.attributes.find((attr) => attr.name === 'profileStages')?.value ||
        []
      );
    },
  }))
  .actions((self) => ({
    setAddresses(addresses: any) {
      self.addresses = cast(addresses);
    },
    setPayments(payments: any) {
      self.paymentMethods = cast(payments);
    },
    setProfileStageCms(profileStageCms: any) {
      self.profileStageCms = cast(profileStageCms);
    },
    updateIsProfileStageNotSkipped() {
      self.isProfileStageNotSkipped = checkProfileStageSkipUntil();
    },
  }));

export const CancelReasonsModel = types.model('Reasons').props({
  data: types.optional(types.array(ReasonsModel), []),
});

export interface Customer extends Instance<typeof CustomerModel> {}
export interface Reasons extends Instance<typeof CancelReasonsModel> {}
