// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { AgeRanges } from "./AgeRangesEnum"
import { AppActionModelOrderBy } from "./AppActionModelOrderByEnum"
import { AppActionRecordModel, AppActionRecordModelType } from "./AppActionRecordModel"
import { AppActionRecordModelSelector } from "./AppActionRecordModel.base"
import { AppContentModel, AppContentModelType } from "./AppContentModel"
import { AppContentModelSelector } from "./AppContentModel.base"
import { AppContentModelOrderBy } from "./AppContentModelOrderByEnum"
import { AppContentRecordModel, AppContentRecordModelType } from "./AppContentRecordModel"
import { AppContentRecordModelSelector } from "./AppContentRecordModel.base"
import { AppDiscoverTileModelOrderBy } from "./AppDiscoverTileModelOrderByEnum"
import { AppDiscoverTileRecordModel, AppDiscoverTileRecordModelType } from "./AppDiscoverTileRecordModel"
import { AppDiscoverTileRecordModelSelector } from "./AppDiscoverTileRecordModel.base"
import { AppFilterModelOrderBy } from "./AppFilterModelOrderByEnum"
import { AppFilterRecordModel, AppFilterRecordModelType } from "./AppFilterRecordModel"
import { AppFilterRecordModelSelector } from "./AppFilterRecordModel.base"
import { AppOrderScreenModelOrderBy } from "./AppOrderScreenModelOrderByEnum"
import { AppOrderScreenRecordModel, AppOrderScreenRecordModelType } from "./AppOrderScreenRecordModel"
import { AppOrderScreenRecordModelSelector } from "./AppOrderScreenRecordModel.base"
import { AppTipModelOrderBy } from "./AppTipModelOrderByEnum"
import { AppTipRecordModel, AppTipRecordModelType } from "./AppTipRecordModel"
import { AppTipRecordModelSelector } from "./AppTipRecordModel.base"
import { BasketRtoModel, BasketRtoModelType } from "./BasketRtoModel"
import { BasketRtoModelSelector } from "./BasketRtoModel.base"
import { BlogArticleModelOrderBy } from "./BlogArticleModelOrderByEnum"
import { BlogArticleRecordModel, BlogArticleRecordModelType } from "./BlogArticleRecordModel"
import { BlogArticleRecordModelSelector } from "./BlogArticleRecordModel.base"
import { BlogCategoryModelOrderBy } from "./BlogCategoryModelOrderByEnum"
import { BlogCategoryRecordModel, BlogCategoryRecordModelType } from "./BlogCategoryRecordModel"
import { BlogCategoryRecordModelSelector } from "./BlogCategoryRecordModel.base"
import { CartModel, CartModelType } from "./CartModel"
import { CartModelSelector } from "./CartModel.base"
import { CollectionMetadataModel, CollectionMetadataModelType } from "./CollectionMetadataModel"
import { CollectionMetadataModelSelector } from "./CollectionMetadataModel.base"
import { ContentSubType } from "./ContentSubTypeEnum"
import { ContentType } from "./ContentTypeEnum"
import { CustomerActivityModel, CustomerActivityModelType } from "./CustomerActivityModel"
import { CustomerActivityModelSelector } from "./CustomerActivityModel.base"
import { CustomerIdentifierQuestionsOutputModel, CustomerIdentifierQuestionsOutputModelType } from "./CustomerIdentifierQuestionsOutputModel"
import { CustomerIdentifierQuestionsOutputModelSelector } from "./CustomerIdentifierQuestionsOutputModel.base"
import { CustomerInfoModel, CustomerInfoModelType } from "./CustomerInfoModel"
import { CustomerInfoModelSelector } from "./CustomerInfoModel.base"
import { CustomerPaymentModel, CustomerPaymentModelType } from "./CustomerPaymentModel"
import { CustomerPaymentModelSelector } from "./CustomerPaymentModel.base"
import { CustomerProfileModel, CustomerProfileModelType } from "./CustomerProfileModel"
import { CustomerProfileModelSelector } from "./CustomerProfileModel.base"
import { DashboardBannerModel, DashboardBannerModelType } from "./DashboardBannerModel"
import { DashboardBannerModelSelector } from "./DashboardBannerModel.base"
import { DashboardModel, DashboardModelType } from "./DashboardModel"
import { DashboardModelSelector } from "./DashboardModel.base"
import { DeliveryInfoLookupType } from "./DeliveryInfoLookupTypeEnum"
import { DeliveryInfoModel, DeliveryInfoModelType } from "./DeliveryInfoModel"
import { DeliveryInfoModelSelector } from "./DeliveryInfoModel.base"
import { DownloadAppModelOrderBy } from "./DownloadAppModelOrderByEnum"
import { DownloadAppRecordModel, DownloadAppRecordModelType } from "./DownloadAppRecordModel"
import { DownloadAppRecordModelSelector } from "./DownloadAppRecordModel.base"
import { DynamicLandingPageCampaignModelOrderBy } from "./DynamicLandingPageCampaignModelOrderByEnum"
import { DynamicLandingPageCampaignRecordModel, DynamicLandingPageCampaignRecordModelType } from "./DynamicLandingPageCampaignRecordModel"
import { DynamicLandingPageCampaignRecordModelSelector } from "./DynamicLandingPageCampaignRecordModel.base"
import { FileFieldModel, FileFieldModelType } from "./FileFieldModel"
import { FileFieldModelSelector } from "./FileFieldModel.base"
import { FilterModelOrderBy } from "./FilterModelOrderByEnum"
import { FilterRecordModel, FilterRecordModelType } from "./FilterRecordModel"
import { FilterRecordModelSelector } from "./FilterRecordModel.base"
import { FooterRecordModel, FooterRecordModelType } from "./FooterRecordModel"
import { FooterRecordModelSelector } from "./FooterRecordModel.base"
import { Gender } from "./GenderEnum"
import { GeneralPageModelOrderBy } from "./GeneralPageModelOrderByEnum"
import { GeneralPageRecordModel, GeneralPageRecordModelType } from "./GeneralPageRecordModel"
import { GeneralPageRecordModelSelector } from "./GeneralPageRecordModel.base"
import { GiftCardModel, GiftCardModelType } from "./GiftCardModel"
import { GiftCardModelSelector } from "./GiftCardModel.base"
import { GoalBasedPlanModelOrderBy } from "./GoalBasedPlanModelOrderByEnum"
import { GoalBasedPlanRecordModel, GoalBasedPlanRecordModelType } from "./GoalBasedPlanRecordModel"
import { GoalBasedPlanRecordModelSelector } from "./GoalBasedPlanRecordModel.base"
import { GoalBasedPlanRtoModel, GoalBasedPlanRtoModelType } from "./GoalBasedPlanRtoModel"
import { GoalBasedPlanRtoModelSelector } from "./GoalBasedPlanRtoModel.base"
import { GoalBasedQuizModelOrderBy } from "./GoalBasedQuizModelOrderByEnum"
import { GoalBasedQuizRecordModel, GoalBasedQuizRecordModelType } from "./GoalBasedQuizRecordModel"
import { GoalBasedQuizRecordModelSelector } from "./GoalBasedQuizRecordModel.base"
import { Goal } from "./GoalEnum"
import { GuestCartModel, GuestCartModelType } from "./GuestCartModel"
import { GuestCartModelSelector } from "./GuestCartModel.base"
import { LandingPageModelOrderBy } from "./LandingPageModelOrderByEnum"
import { LandingPageRecordModel, LandingPageRecordModelType } from "./LandingPageRecordModel"
import { LandingPageRecordModelSelector } from "./LandingPageRecordModel.base"
import { LandingPageV2ModelOrderBy } from "./LandingPageV2ModelOrderByEnum"
import { LandingPageV2RecordModel, LandingPageV2RecordModelType } from "./LandingPageV2RecordModel"
import { LandingPageV2RecordModelSelector } from "./LandingPageV2RecordModel.base"
import { MealPlanModel, MealPlanModelType } from "./MealPlanModel"
import { MealPlanModelSelector } from "./MealPlanModel.base"
import { MealPlanModelOrderBy } from "./MealPlanModelOrderByEnum"
import { MealPlanRecordModel, MealPlanRecordModelType } from "./MealPlanRecordModel"
import { MealPlanRecordModelSelector } from "./MealPlanRecordModel.base"
import { MealPlanSubCategory } from "./MealPlanSubCategoryEnum"
import { MenuHierarchyModelOrderBy } from "./MenuHierarchyModelOrderByEnum"
import { MenuHierarchyRecordModel, MenuHierarchyRecordModelType } from "./MenuHierarchyRecordModel"
import { MenuHierarchyRecordModelSelector } from "./MenuHierarchyRecordModel.base"
import { MenuPageSeoModelOrderBy } from "./MenuPageSeoModelOrderByEnum"
import { MenuPageSeoRecordModel, MenuPageSeoRecordModelType } from "./MenuPageSeoRecordModel"
import { MenuPageSeoRecordModelSelector } from "./MenuPageSeoRecordModel.base"
import { MenuSortOptionModelOrderBy } from "./MenuSortOptionModelOrderByEnum"
import { MenuSortOptionRecordModel, MenuSortOptionRecordModelType } from "./MenuSortOptionRecordModel"
import { MenuSortOptionRecordModelSelector } from "./MenuSortOptionRecordModel.base"
import { MessageRtoModel, MessageRtoModelType } from "./MessageRtoModel"
import { MessageRtoModelSelector } from "./MessageRtoModel.base"
import { NavbarRecordModel, NavbarRecordModelType } from "./NavbarRecordModel"
import { NavbarRecordModelSelector } from "./NavbarRecordModel.base"
import { OrderDeliveryInfoModel, OrderDeliveryInfoModelType } from "./OrderDeliveryInfoModel"
import { OrderDeliveryInfoModelSelector } from "./OrderDeliveryInfoModel.base"
import { OrderModel, OrderModelType } from "./OrderModel"
import { OrderModelSelector } from "./OrderModel.base"
import { OrderPlanCategoryModelOrderBy } from "./OrderPlanCategoryModelOrderByEnum"
import { OrderPlanCategoryRecordModel, OrderPlanCategoryRecordModelType } from "./OrderPlanCategoryRecordModel"
import { OrderPlanCategoryRecordModelSelector } from "./OrderPlanCategoryRecordModel.base"
import { PlanMetaModel, PlanMetaModelType } from "./PlanMetaModel"
import { PlanMetaModelSelector } from "./PlanMetaModel.base"
import { PricingGroupModelOrderBy } from "./PricingGroupModelOrderByEnum"
import { PricingGroupRecordModel, PricingGroupRecordModelType } from "./PricingGroupRecordModel"
import { PricingGroupRecordModelSelector } from "./PricingGroupRecordModel.base"
import { ProductCategoryAppModelOrderBy } from "./ProductCategoryAppModelOrderByEnum"
import { ProductCategoryAppRecordModel, ProductCategoryAppRecordModelType } from "./ProductCategoryAppRecordModel"
import { ProductCategoryAppRecordModelSelector } from "./ProductCategoryAppRecordModel.base"
import { ProductCategoryModelOrderBy } from "./ProductCategoryModelOrderByEnum"
import { ProductCategoryRecordModel, ProductCategoryRecordModelType } from "./ProductCategoryRecordModel"
import { ProductCategoryRecordModelSelector } from "./ProductCategoryRecordModel.base"
import { ProductCollectionDtoModel, ProductCollectionDtoModelType } from "./ProductCollectionDtoModel"
import { ProductCollectionDtoModelSelector } from "./ProductCollectionDtoModel.base"
import { ProductDietaryTagModelOrderBy } from "./ProductDietaryTagModelOrderByEnum"
import { ProductDietaryTagRecordModel, ProductDietaryTagRecordModelType } from "./ProductDietaryTagRecordModel"
import { ProductDietaryTagRecordModelSelector } from "./ProductDietaryTagRecordModel.base"
import { ProductInstructionModelOrderBy } from "./ProductInstructionModelOrderByEnum"
import { ProductInstructionRecordModel, ProductInstructionRecordModelType } from "./ProductInstructionRecordModel"
import { ProductInstructionRecordModelSelector } from "./ProductInstructionRecordModel.base"
import { ProductModel, ProductModelType } from "./ProductModel"
import { ProductModelSelector } from "./ProductModel.base"
import { ProductModelOrderBy } from "./ProductModelOrderByEnum"
import { ProductPrimaryTagModelOrderBy } from "./ProductPrimaryTagModelOrderByEnum"
import { ProductPrimaryTagRecordModel, ProductPrimaryTagRecordModelType } from "./ProductPrimaryTagRecordModel"
import { ProductPrimaryTagRecordModelSelector } from "./ProductPrimaryTagRecordModel.base"
import { ProductRecommendationRecordModel, ProductRecommendationRecordModelType } from "./ProductRecommendationRecordModel"
import { ProductRecommendationRecordModelSelector } from "./ProductRecommendationRecordModel.base"
import { ProductRecordModel, ProductRecordModelType } from "./ProductRecordModel"
import { ProductRecordModelSelector } from "./ProductRecordModel.base"
import { ProductRestrictionLimitModelOrderBy } from "./ProductRestrictionLimitModelOrderByEnum"
import { ProductRestrictionLimitRecordModel, ProductRestrictionLimitRecordModelType } from "./ProductRestrictionLimitRecordModel"
import { ProductRestrictionLimitRecordModelSelector } from "./ProductRestrictionLimitRecordModel.base"
import { ProductSecondaryTagModelOrderBy } from "./ProductSecondaryTagModelOrderByEnum"
import { ProductSecondaryTagRecordModel, ProductSecondaryTagRecordModelType } from "./ProductSecondaryTagRecordModel"
import { ProductSecondaryTagRecordModelSelector } from "./ProductSecondaryTagRecordModel.base"
import { ProductSubcategoryModelOrderBy } from "./ProductSubcategoryModelOrderByEnum"
import { ProductSubcategoryRecordModel, ProductSubcategoryRecordModelType } from "./ProductSubcategoryRecordModel"
import { ProductSubcategoryRecordModelSelector } from "./ProductSubcategoryRecordModel.base"
import { ProductTagModelOrderBy } from "./ProductTagModelOrderByEnum"
import { ProductTagRecordModel, ProductTagRecordModelType } from "./ProductTagRecordModel"
import { ProductTagRecordModelSelector } from "./ProductTagRecordModel.base"
import { ProductTertiaryTagModelOrderBy } from "./ProductTertiaryTagModelOrderByEnum"
import { ProductTertiaryTagRecordModel, ProductTertiaryTagRecordModelType } from "./ProductTertiaryTagRecordModel"
import { ProductTertiaryTagRecordModelSelector } from "./ProductTertiaryTagRecordModel.base"
import { ProfileStageModelOrderBy } from "./ProfileStageModelOrderByEnum"
import { ProfileStageRecordModel, ProfileStageRecordModelType } from "./ProfileStageRecordModel"
import { ProfileStageRecordModelSelector } from "./ProfileStageRecordModel.base"
import { ReasonModelOrderBy } from "./ReasonModelOrderByEnum"
import { ReasonRecordModel, ReasonRecordModelType } from "./ReasonRecordModel"
import { ReasonRecordModelSelector } from "./ReasonRecordModel.base"
import { ReorderRtoModel, ReorderRtoModelType } from "./ReorderRtoModel"
import { ReorderRtoModelSelector } from "./ReorderRtoModel.base"
import { RetailMenuHierarchyModelOrderBy } from "./RetailMenuHierarchyModelOrderByEnum"
import { RetailMenuHierarchyRecordModel, RetailMenuHierarchyRecordModelType } from "./RetailMenuHierarchyRecordModel"
import { RetailMenuHierarchyRecordModelSelector } from "./RetailMenuHierarchyRecordModel.base"
import { RetailNavbarRecordModel, RetailNavbarRecordModelType } from "./RetailNavbarRecordModel"
import { RetailNavbarRecordModelSelector } from "./RetailNavbarRecordModel.base"
import { RetailPageModelOrderBy } from "./RetailPageModelOrderByEnum"
import { RetailPageRecordModel, RetailPageRecordModelType } from "./RetailPageRecordModel"
import { RetailPageRecordModelSelector } from "./RetailPageRecordModel.base"
import { AppActionModelFilter, AppContentModelFilter, AppDiscoverTileModelFilter, AppFilterModelFilter, AppOrderScreenModelFilter, AppTipModelFilter, BlogArticleModelFilter, BlogCategoryModelFilter, CartInput, CustomerActivitySortInput, CustomerActivityWhereInput, CustomerIdentifierAnswersInput, CustomerIdentifierInput, DownloadAppModelFilter, DynamicLandingPageCampaignModelFilter, FilterModelFilter, GeneralPageModelFilter, GoalBasedPlanModelFilter, GoalBasedQuizModelFilter, GuestCartInput, LandingPageModelFilter, LandingPageV2ModelFilter, MealPlanModelFilter, MealPlanSortInput, MealPlanWhereInput, MenuHierarchyModelFilter, MenuPageSeoModelFilter, MenuSortOptionModelFilter, OrderDeliveryInfoInput, OrderPlanCategoryModelFilter, OrderSortInput, OrderWhereInput, PrepareCustomerIdentifierCodeInput, PricingGroupModelFilter, ProductCategoryAppModelFilter, ProductCategoryModelFilter, ProductCollectionSortInput, ProductCollectionWhereInput, ProductDietaryTagModelFilter, ProductInstructionModelFilter, ProductModelFilter, ProductPrimaryTagModelFilter, ProductRestrictionLimitModelFilter, ProductSecondaryTagModelFilter, ProductSortInput, ProductSubcategoryModelFilter, ProductTagModelFilter, ProductTertiaryTagModelFilter, ProductWhereInput, ProfileStageModelFilter, ReasonModelFilter, RetailMenuHierarchyModelFilter, RetailPageModelFilter, SiteContentModelFilter, SortMenuModelFilter, SubscriptionInstructionModelFilter, SvgTestModelFilter, SwapConfigModelFilter, ToplineBannerRetailModelFilter, ToplineBannerV2ModelFilter, UiContentModelFilter, UploadFilter, VerifyCustomerProfileStageInput, WebPromoBannerModelFilter, WeeklyPlanSortInput, WeeklyPlanWhereInput, WlpMealPlanSortInput, YoutubeInput, ZipCheckoutsDto, ZoneModelFilter } from "./RootStore.base"
import { SiteContentModelOrderBy } from "./SiteContentModelOrderByEnum"
import { SiteContentRecordModel, SiteContentRecordModelType } from "./SiteContentRecordModel"
import { SiteContentRecordModelSelector } from "./SiteContentRecordModel.base"
import { SiteLocale } from "./SiteLocaleEnum"
import { SiteModel, SiteModelType } from "./SiteModel"
import { SiteModelSelector } from "./SiteModel.base"
import { SortMenuModelOrderBy } from "./SortMenuModelOrderByEnum"
import { SortMenuRecordModel, SortMenuRecordModelType } from "./SortMenuRecordModel"
import { SortMenuRecordModelSelector } from "./SortMenuRecordModel.base"
import { SubscriptionEntityModel, SubscriptionEntityModelType } from "./SubscriptionEntityModel"
import { SubscriptionEntityModelSelector } from "./SubscriptionEntityModel.base"
import { SubscriptionInstructionModelOrderBy } from "./SubscriptionInstructionModelOrderByEnum"
import { SubscriptionInstructionRecordModel, SubscriptionInstructionRecordModelType } from "./SubscriptionInstructionRecordModel"
import { SubscriptionInstructionRecordModelSelector } from "./SubscriptionInstructionRecordModel.base"
import { SvgTestModelOrderBy } from "./SvgTestModelOrderByEnum"
import { SvgTestRecordModel, SvgTestRecordModelType } from "./SvgTestRecordModel"
import { SvgTestRecordModelSelector } from "./SvgTestRecordModel.base"
import { SwapConfigModelOrderBy } from "./SwapConfigModelOrderByEnum"
import { SwapConfigRecordModel, SwapConfigRecordModelType } from "./SwapConfigRecordModel"
import { SwapConfigRecordModelSelector } from "./SwapConfigRecordModel.base"
import { TokenRtoModel, TokenRtoModelType } from "./TokenRtoModel"
import { TokenRtoModelSelector } from "./TokenRtoModel.base"
import { ToplineBannerRetailModelOrderBy } from "./ToplineBannerRetailModelOrderByEnum"
import { ToplineBannerRetailRecordModel, ToplineBannerRetailRecordModelType } from "./ToplineBannerRetailRecordModel"
import { ToplineBannerRetailRecordModelSelector } from "./ToplineBannerRetailRecordModel.base"
import { ToplineBannerV2ModelOrderBy } from "./ToplineBannerV2ModelOrderByEnum"
import { ToplineBannerV2RecordModel, ToplineBannerV2RecordModelType } from "./ToplineBannerV2RecordModel"
import { ToplineBannerV2RecordModelSelector } from "./ToplineBannerV2RecordModel.base"
import { UiContentModelOrderBy } from "./UiContentModelOrderByEnum"
import { UiContentRecordModel, UiContentRecordModelType } from "./UiContentRecordModel"
import { UiContentRecordModelSelector } from "./UiContentRecordModel.base"
import { UploadOrderBy } from "./UploadOrderByEnum"
import { WebPromoBannerModelOrderBy } from "./WebPromoBannerModelOrderByEnum"
import { WebPromoBannerRecordModel, WebPromoBannerRecordModelType } from "./WebPromoBannerRecordModel"
import { WebPromoBannerRecordModelSelector } from "./WebPromoBannerRecordModel.base"
import { WeeklyPlanRtoModel, WeeklyPlanRtoModelType } from "./WeeklyPlanRtoModel"
import { WeeklyPlanRtoModelSelector } from "./WeeklyPlanRtoModel.base"
import { WellnessRtoModel, WellnessRtoModelType } from "./WellnessRtoModel"
import { WellnessRtoModelSelector } from "./WellnessRtoModel.base"
import { WlpMealPlansRtoModel, WlpMealPlansRtoModelType } from "./WlpMealPlansRtoModel"
import { WlpMealPlansRtoModelSelector } from "./WlpMealPlansRtoModel.base"
import { ZipCheckoutsRtoModel, ZipCheckoutsRtoModelType } from "./ZipCheckoutsRtoModel"
import { ZipCheckoutsRtoModelSelector } from "./ZipCheckoutsRtoModel.base"
import { ZoneModelOrderBy } from "./ZoneModelOrderByEnum"
import { ZoneRecordModel, ZoneRecordModelType } from "./ZoneRecordModel"
import { ZoneRecordModelSelector } from "./ZoneRecordModel.base"
import { RootStoreType } from "./index"


/**
 * QueryRootBase
 * auto generated base class for the model QueryRootModel.
 */
export const QueryRootModelBase = ModelBase
  .named('QueryRoot')
  .props({
    __typename: types.optional(types.literal("query_root"), "query_root"),
    /** Returns meta information regarding a record collection */
    _allAppActionsMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allAppContentsMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allAppDiscoverTilesMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allAppFiltersMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allAppOrderScreensMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allAppTipsMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allBlogArticlesMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allBlogCategoriesMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allDownloadAppsMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allDynamicLandingPageCampaignsMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allFiltersMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allGeneralPagesMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allGoalBasedPlansMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allGoalBasedQuizzesMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allLandingPageV2sMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allLandingPagesMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allMealPlansMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allMenuHierarchiesMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allMenuPageSeosMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allMenuSortOptionsMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allOrderPlanCategoriesMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allPricingGroupsMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allProductCategoriesMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allProductCategoryAppsMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allProductDietaryTagsMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allProductInstructionsMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allProductPrimaryTagsMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allProductRestrictionLimitsMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allProductSecondaryTagsMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allProductSubcategoriesMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allProductTagsMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allProductTertiaryTagsMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allProductsMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allProfileStagesMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allReasonsMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allRetailMenuHierarchiesMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allRetailPagesMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allSiteContentsMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allSortMenusMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allSubscriptionInstructionsMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allSvgTestsMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allSwapConfigsMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allToplineBannerRetailsMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allToplineBannerV2sMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allUiContentsMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding an assets collection */
    _allUploadsMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allWebPromoBannersMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns meta information regarding a record collection */
    _allZonesMetaCms: types.union(types.undefined, types.late((): any => CollectionMetadataModel)),
    /** Returns the single instance record */
    _siteCms: types.union(types.undefined, types.late((): any => SiteModel)),
    /** Returns a collection of records */
    allAppActionsCms: types.union(types.undefined, types.array(types.late((): any => AppActionRecordModel))),
    /** Returns a collection of records */
    allAppContentsCms: types.union(types.undefined, types.array(types.late((): any => AppContentRecordModel))),
    /** Returns a collection of records */
    allAppDiscoverTilesCms: types.union(types.undefined, types.array(types.late((): any => AppDiscoverTileRecordModel))),
    /** Returns a collection of records */
    allAppFiltersCms: types.union(types.undefined, types.array(types.late((): any => AppFilterRecordModel))),
    /** Returns a collection of records */
    allAppOrderScreensCms: types.union(types.undefined, types.array(types.late((): any => AppOrderScreenRecordModel))),
    /** Returns a collection of records */
    allAppTipsCms: types.union(types.undefined, types.array(types.late((): any => AppTipRecordModel))),
    /** Returns a collection of records */
    allBlogArticlesCms: types.union(types.undefined, types.array(types.late((): any => BlogArticleRecordModel))),
    /** Returns a collection of records */
    allBlogCategoriesCms: types.union(types.undefined, types.array(types.late((): any => BlogCategoryRecordModel))),
    /** Returns a collection of records */
    allDownloadAppsCms: types.union(types.undefined, types.array(types.late((): any => DownloadAppRecordModel))),
    /** Returns a collection of records */
    allDynamicLandingPageCampaignsCms: types.union(types.undefined, types.array(types.late((): any => DynamicLandingPageCampaignRecordModel))),
    /** Returns a collection of records */
    allFiltersCms: types.union(types.undefined, types.array(types.late((): any => FilterRecordModel))),
    /** Returns a collection of records */
    allGeneralPagesCms: types.union(types.undefined, types.array(types.late((): any => GeneralPageRecordModel))),
    /** Returns a collection of records */
    allGoalBasedPlansCms: types.union(types.undefined, types.array(types.late((): any => GoalBasedPlanRecordModel))),
    /** Returns a collection of records */
    allGoalBasedQuizzesCms: types.union(types.undefined, types.array(types.late((): any => GoalBasedQuizRecordModel))),
    /** Returns a collection of records */
    allLandingPageV2sCms: types.union(types.undefined, types.array(types.late((): any => LandingPageV2RecordModel))),
    /** Returns a collection of records */
    allLandingPagesCms: types.union(types.undefined, types.array(types.late((): any => LandingPageRecordModel))),
    /** Returns a collection of records */
    allMealPlansCms: types.union(types.undefined, types.array(types.late((): any => MealPlanRecordModel))),
    /** Returns a collection of records */
    allMenuHierarchiesCms: types.union(types.undefined, types.array(types.late((): any => MenuHierarchyRecordModel))),
    /** Returns a collection of records */
    allMenuPageSeosCms: types.union(types.undefined, types.array(types.late((): any => MenuPageSeoRecordModel))),
    /** Returns a collection of records */
    allMenuSortOptionsCms: types.union(types.undefined, types.array(types.late((): any => MenuSortOptionRecordModel))),
    /** Returns a collection of records */
    allOrderPlanCategoriesCms: types.union(types.undefined, types.array(types.late((): any => OrderPlanCategoryRecordModel))),
    /** Returns a collection of records */
    allPricingGroupsCms: types.union(types.undefined, types.array(types.late((): any => PricingGroupRecordModel))),
    /** Returns a collection of records */
    allProductCategoriesCms: types.union(types.undefined, types.array(types.late((): any => ProductCategoryRecordModel))),
    /** Returns a collection of records */
    allProductCategoryAppsCms: types.union(types.undefined, types.array(types.late((): any => ProductCategoryAppRecordModel))),
    /** Returns a collection of records */
    allProductDietaryTagsCms: types.union(types.undefined, types.array(types.late((): any => ProductDietaryTagRecordModel))),
    /** Returns a collection of records */
    allProductInstructionsCms: types.union(types.undefined, types.array(types.late((): any => ProductInstructionRecordModel))),
    /** Returns a collection of records */
    allProductPrimaryTagsCms: types.union(types.undefined, types.array(types.late((): any => ProductPrimaryTagRecordModel))),
    /** Returns a collection of records */
    allProductRestrictionLimitsCms: types.union(types.undefined, types.array(types.late((): any => ProductRestrictionLimitRecordModel))),
    /** Returns a collection of records */
    allProductSecondaryTagsCms: types.union(types.undefined, types.array(types.late((): any => ProductSecondaryTagRecordModel))),
    /** Returns a collection of records */
    allProductSubcategoriesCms: types.union(types.undefined, types.array(types.late((): any => ProductSubcategoryRecordModel))),
    /** Returns a collection of records */
    allProductTagsCms: types.union(types.undefined, types.array(types.late((): any => ProductTagRecordModel))),
    /** Returns a collection of records */
    allProductTertiaryTagsCms: types.union(types.undefined, types.array(types.late((): any => ProductTertiaryTagRecordModel))),
    /** Returns a collection of records */
    allProductsCms: types.union(types.undefined, types.array(types.late((): any => ProductRecordModel))),
    /** Returns a collection of records */
    allProfileStagesCms: types.union(types.undefined, types.array(types.late((): any => ProfileStageRecordModel))),
    /** Returns a collection of records */
    allReasonsCms: types.union(types.undefined, types.array(types.late((): any => ReasonRecordModel))),
    /** Returns a collection of records */
    allRetailMenuHierarchiesCms: types.union(types.undefined, types.array(types.late((): any => RetailMenuHierarchyRecordModel))),
    /** Returns a collection of records */
    allRetailPagesCms: types.union(types.undefined, types.array(types.late((): any => RetailPageRecordModel))),
    /** Returns a collection of records */
    allSiteContentsCms: types.union(types.undefined, types.array(types.late((): any => SiteContentRecordModel))),
    /** Returns a collection of records */
    allSortMenusCms: types.union(types.undefined, types.array(types.late((): any => SortMenuRecordModel))),
    /** Returns a collection of records */
    allSubscriptionInstructionsCms: types.union(types.undefined, types.array(types.late((): any => SubscriptionInstructionRecordModel))),
    /** Returns a collection of records */
    allSvgTestsCms: types.union(types.undefined, types.array(types.late((): any => SvgTestRecordModel))),
    /** Returns a collection of records */
    allSwapConfigsCms: types.union(types.undefined, types.array(types.late((): any => SwapConfigRecordModel))),
    /** Returns a collection of records */
    allToplineBannerRetailsCms: types.union(types.undefined, types.array(types.late((): any => ToplineBannerRetailRecordModel))),
    /** Returns a collection of records */
    allToplineBannerV2sCms: types.union(types.undefined, types.array(types.late((): any => ToplineBannerV2RecordModel))),
    /** Returns a collection of records */
    allUiContentsCms: types.union(types.undefined, types.array(types.late((): any => UiContentRecordModel))),
    /** Returns a collection of assets */
    allUploadsCms: types.union(types.undefined, types.array(types.late((): any => FileFieldModel))),
    /** Returns a collection of records */
    allWebPromoBannersCms: types.union(types.undefined, types.array(types.late((): any => WebPromoBannerRecordModel))),
    /** Returns a collection of records */
    allZonesCms: types.union(types.undefined, types.array(types.late((): any => ZoneRecordModel))),
    /** Returns a specific record */
    appActionCms: types.union(types.undefined, types.null, types.late((): any => AppActionRecordModel)),
    appContent: types.union(types.undefined, types.array(types.late((): any => AppContentModel))),
    /** Returns a specific record */
    appContentCms: types.union(types.undefined, types.null, types.late((): any => AppContentRecordModel)),
    /** Returns a specific record */
    appDiscoverTileCms: types.union(types.undefined, types.null, types.late((): any => AppDiscoverTileRecordModel)),
    /** Returns a specific record */
    appFilterCms: types.union(types.undefined, types.null, types.late((): any => AppFilterRecordModel)),
    /** Returns a specific record */
    appOrderScreenCms: types.union(types.undefined, types.null, types.late((): any => AppOrderScreenRecordModel)),
    /** Returns a specific record */
    appTipCms: types.union(types.undefined, types.null, types.late((): any => AppTipRecordModel)),
    basket: types.union(types.undefined, types.late((): any => BasketRtoModel)),
    /** Returns a specific record */
    blogArticleCms: types.union(types.undefined, types.null, types.late((): any => BlogArticleRecordModel)),
    /** Returns a specific record */
    blogCategoryCms: types.union(types.undefined, types.null, types.late((): any => BlogCategoryRecordModel)),
    cart: types.union(types.undefined, types.late((): any => CartModel)),
    clientToken: types.union(types.undefined, types.late((): any => TokenRtoModel)),
    customer: types.union(types.undefined, types.late((): any => CustomerProfileModel)),
    customerActivities: types.union(types.undefined, types.array(types.late((): any => CustomerActivityModel))),
    customerActivity: types.union(types.undefined, types.late((): any => CustomerActivityModel)),
    customerInfo: types.union(types.undefined, types.late((): any => CustomerInfoModel)),
    dashboard: types.union(types.undefined, types.late((): any => DashboardModel)),
    dashboardBanner: types.union(types.undefined, types.late((): any => DashboardBannerModel)),
    deliveryInfo: types.union(types.undefined, types.array(types.late((): any => DeliveryInfoModel))),
    /** Returns a specific record */
    downloadAppCms: types.union(types.undefined, types.null, types.late((): any => DownloadAppRecordModel)),
    /** Returns a specific record */
    dynamicLandingPageCampaignCms: types.union(types.undefined, types.null, types.late((): any => DynamicLandingPageCampaignRecordModel)),
    /** Returns a specific record */
    filterCms: types.union(types.undefined, types.null, types.late((): any => FilterRecordModel)),
    /** Returns the single instance record */
    footerCms: types.union(types.undefined, types.null, types.late((): any => FooterRecordModel)),
    /** Returns a specific record */
    generalPageCms: types.union(types.undefined, types.null, types.late((): any => GeneralPageRecordModel)),
    generateIdentifierCode: types.union(types.undefined, types.late((): any => MessageRtoModel)),
    giftCard: types.union(types.undefined, types.late((): any => GiftCardModel)),
    /** Returns a specific record */
    goalBasedPlanCms: types.union(types.undefined, types.null, types.late((): any => GoalBasedPlanRecordModel)),
    goalBasedPlans: types.union(types.undefined, types.array(types.late((): any => GoalBasedPlanRtoModel))),
    /** Returns a specific record */
    goalBasedQuizCms: types.union(types.undefined, types.null, types.late((): any => GoalBasedQuizRecordModel)),
    guestCart: types.union(types.undefined, types.late((): any => GuestCartModel)),
    initiateEmailChange: types.union(types.undefined, types.late((): any => CustomerIdentifierQuestionsOutputModel)),
    /** Returns a specific record */
    landingPageCms: types.union(types.undefined, types.null, types.late((): any => LandingPageRecordModel)),
    /** Returns a specific record */
    landingPageV2Cms: types.union(types.undefined, types.null, types.late((): any => LandingPageV2RecordModel)),
    mealPlan: types.union(types.undefined, types.late((): any => MealPlanModel)),
    /** Returns a specific record */
    mealPlanCms: types.union(types.undefined, types.null, types.late((): any => MealPlanRecordModel)),
    mealPlans: types.union(types.undefined, types.array(types.late((): any => MealPlanModel))),
    /** Returns a specific record */
    menuHierarchyCms: types.union(types.undefined, types.null, types.late((): any => MenuHierarchyRecordModel)),
    /** Returns a specific record */
    menuPageSeoCms: types.union(types.undefined, types.null, types.late((): any => MenuPageSeoRecordModel)),
    /** Returns a specific record */
    menuSortOptionCms: types.union(types.undefined, types.null, types.late((): any => MenuSortOptionRecordModel)),
    /** Returns the single instance record */
    navbarCms: types.union(types.undefined, types.null, types.late((): any => NavbarRecordModel)),
    order: types.union(types.undefined, types.null, types.late((): any => OrderModel)),
    orderDeliveryInfo: types.union(types.undefined, types.late((): any => OrderDeliveryInfoModel)),
    /** Returns a specific record */
    orderPlanCategoryCms: types.union(types.undefined, types.null, types.late((): any => OrderPlanCategoryRecordModel)),
    orders: types.union(types.undefined, types.array(types.late((): any => OrderModel))),
    paymentMethods: types.union(types.undefined, types.array(types.late((): any => CustomerPaymentModel))),
    plans: types.union(types.undefined, types.array(types.late((): any => PlanMetaModel))),
    /** Returns a specific record */
    pricingGroupCms: types.union(types.undefined, types.null, types.late((): any => PricingGroupRecordModel)),
    product: types.union(types.undefined, types.late((): any => ProductModel)),
    /** Returns a specific record */
    productCategoryAppCms: types.union(types.undefined, types.null, types.late((): any => ProductCategoryAppRecordModel)),
    /** Returns a specific record */
    productCategoryCms: types.union(types.undefined, types.null, types.late((): any => ProductCategoryRecordModel)),
    /** Returns a specific record */
    productCms: types.union(types.undefined, types.null, types.late((): any => ProductRecordModel)),
    productCollection: types.union(types.undefined, types.late((): any => ProductCollectionDtoModel)),
    productCollections: types.union(types.undefined, types.array(types.late((): any => ProductCollectionDtoModel))),
    /** Returns a specific record */
    productDietaryTagCms: types.union(types.undefined, types.null, types.late((): any => ProductDietaryTagRecordModel)),
    /** Returns a specific record */
    productInstructionCms: types.union(types.undefined, types.null, types.late((): any => ProductInstructionRecordModel)),
    /** Returns a specific record */
    productPrimaryTagCms: types.union(types.undefined, types.null, types.late((): any => ProductPrimaryTagRecordModel)),
    /** Returns the single instance record */
    productRecommendationCms: types.union(types.undefined, types.null, types.late((): any => ProductRecommendationRecordModel)),
    /** Returns a specific record */
    productRestrictionLimitCms: types.union(types.undefined, types.null, types.late((): any => ProductRestrictionLimitRecordModel)),
    /** Returns a specific record */
    productSecondaryTagCms: types.union(types.undefined, types.null, types.late((): any => ProductSecondaryTagRecordModel)),
    /** Returns a specific record */
    productSubcategoryCms: types.union(types.undefined, types.null, types.late((): any => ProductSubcategoryRecordModel)),
    /** Returns a specific record */
    productTagCms: types.union(types.undefined, types.null, types.late((): any => ProductTagRecordModel)),
    /** Returns a specific record */
    productTertiaryTagCms: types.union(types.undefined, types.null, types.late((): any => ProductTertiaryTagRecordModel)),
    products: types.union(types.undefined, types.array(types.late((): any => ProductModel))),
    /** Returns a specific record */
    profileStageCms: types.union(types.undefined, types.null, types.late((): any => ProfileStageRecordModel)),
    /** Returns a specific record */
    reasonCms: types.union(types.undefined, types.null, types.late((): any => ReasonRecordModel)),
    reorder: types.union(types.undefined, types.late((): any => ReorderRtoModel)),
    /** Returns a specific record */
    retailMenuHierarchyCms: types.union(types.undefined, types.null, types.late((): any => RetailMenuHierarchyRecordModel)),
    /** Returns the single instance record */
    retailNavbarCms: types.union(types.undefined, types.null, types.late((): any => RetailNavbarRecordModel)),
    /** Returns a specific record */
    retailPageCms: types.union(types.undefined, types.null, types.late((): any => RetailPageRecordModel)),
    /** Returns a specific record */
    siteContentCms: types.union(types.undefined, types.null, types.late((): any => SiteContentRecordModel)),
    /** Returns a specific record */
    sortMenuCms: types.union(types.undefined, types.null, types.late((): any => SortMenuRecordModel)),
    subscription: types.union(types.undefined, types.null, types.late((): any => SubscriptionEntityModel)),
    subscriptionDeliveryInfo: types.union(types.undefined, types.array(types.late((): any => DeliveryInfoModel))),
    /** Returns a specific record */
    subscriptionInstructionCms: types.union(types.undefined, types.null, types.late((): any => SubscriptionInstructionRecordModel)),
    /** Returns a specific record */
    svgTestCms: types.union(types.undefined, types.null, types.late((): any => SvgTestRecordModel)),
    /** Returns a specific record */
    swapConfigCms: types.union(types.undefined, types.null, types.late((): any => SwapConfigRecordModel)),
    tokenizationKeys: types.union(types.undefined, types.late((): any => TokenRtoModel)),
    /** Returns a specific record */
    toplineBannerRetailCms: types.union(types.undefined, types.null, types.late((): any => ToplineBannerRetailRecordModel)),
    /** Returns a specific record */
    toplineBannerV2Cms: types.union(types.undefined, types.null, types.late((): any => ToplineBannerV2RecordModel)),
    /** Returns a specific record */
    uiContentCms: types.union(types.undefined, types.null, types.late((): any => UiContentRecordModel)),
    /** Returns a specific asset */
    uploadCms: types.union(types.undefined, types.null, types.late((): any => FileFieldModel)),
    validateCustomerProfileStage: types.union(types.undefined, types.late((): any => MessageRtoModel)),
    validateIdentifierQuestions: types.union(types.undefined, types.late((): any => CustomerIdentifierQuestionsOutputModel)),
    /** Returns a specific record */
    webPromoBannerCms: types.union(types.undefined, types.null, types.late((): any => WebPromoBannerRecordModel)),
    weeklyPlan: types.union(types.undefined, types.late((): any => WeeklyPlanRtoModel)),
    weeklyPlans: types.union(types.undefined, types.array(types.late((): any => WeeklyPlanRtoModel))),
    wellness: types.union(types.undefined, types.late((): any => WellnessRtoModel)),
    wlpMealPlan: types.union(types.undefined, types.late((): any => WlpMealPlansRtoModel)),
    youtubeVideos: types.union(types.undefined, types.frozen()),
    zipCheckouts: types.union(types.undefined, types.late((): any => ZipCheckoutsRtoModel)),
    /** Returns a specific record */
    zoneCms: types.union(types.undefined, types.null, types.late((): any => ZoneRecordModel)),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class QueryRootModelSelector extends QueryBuilder {
  get youtubeVideos() { return this.__attr(`youtubeVideos`) }
  _allAppActionsMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (AppActionModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allAppActionsMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allAppContentsMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (AppContentModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allAppContentsMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allAppDiscoverTilesMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (AppDiscoverTileModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allAppDiscoverTilesMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allAppFiltersMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (AppFilterModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allAppFiltersMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allAppOrderScreensMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (AppOrderScreenModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allAppOrderScreensMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allAppTipsMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (AppTipModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allAppTipsMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allBlogArticlesMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (BlogArticleModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allBlogArticlesMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allBlogCategoriesMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (BlogCategoryModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allBlogCategoriesMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allDownloadAppsMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (DownloadAppModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allDownloadAppsMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allDynamicLandingPageCampaignsMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (DynamicLandingPageCampaignModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allDynamicLandingPageCampaignsMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allFiltersMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (FilterModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allFiltersMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allGeneralPagesMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (GeneralPageModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allGeneralPagesMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allGoalBasedPlansMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (GoalBasedPlanModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allGoalBasedPlansMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allGoalBasedQuizzesMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (GoalBasedQuizModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allGoalBasedQuizzesMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allLandingPageV2sMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (LandingPageV2ModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allLandingPageV2sMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allLandingPagesMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (LandingPageModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allLandingPagesMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allMealPlansMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (MealPlanModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allMealPlansMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allMenuHierarchiesMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (MenuHierarchyModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allMenuHierarchiesMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allMenuPageSeosMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (MenuPageSeoModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allMenuPageSeosMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allMenuSortOptionsMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (MenuSortOptionModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allMenuSortOptionsMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allOrderPlanCategoriesMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (OrderPlanCategoryModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allOrderPlanCategoriesMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allPricingGroupsMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (PricingGroupModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allPricingGroupsMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allProductCategoriesMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ProductCategoryModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allProductCategoriesMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allProductCategoryAppsMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ProductCategoryAppModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allProductCategoryAppsMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allProductDietaryTagsMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ProductDietaryTagModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allProductDietaryTagsMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allProductInstructionsMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ProductInstructionModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allProductInstructionsMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allProductPrimaryTagsMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ProductPrimaryTagModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allProductPrimaryTagsMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allProductRestrictionLimitsMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ProductRestrictionLimitModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allProductRestrictionLimitsMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allProductSecondaryTagsMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ProductSecondaryTagModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allProductSecondaryTagsMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allProductSubcategoriesMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ProductSubcategoryModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allProductSubcategoriesMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allProductTagsMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ProductTagModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allProductTagsMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allProductTertiaryTagsMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ProductTertiaryTagModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allProductTertiaryTagsMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allProductsMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ProductModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allProductsMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allProfileStagesMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ProfileStageModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allProfileStagesMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allReasonsMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ReasonModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allReasonsMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allRetailMenuHierarchiesMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (RetailMenuHierarchyModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allRetailMenuHierarchiesMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allRetailPagesMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (RetailPageModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allRetailPagesMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allSiteContentsMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (SiteContentModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allSiteContentsMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allSortMenusMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (SortMenuModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allSortMenusMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allSubscriptionInstructionsMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (SubscriptionInstructionModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allSubscriptionInstructionsMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allSvgTestsMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (SvgTestModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allSvgTestsMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allSwapConfigsMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (SwapConfigModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allSwapConfigsMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allToplineBannerRetailsMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ToplineBannerRetailModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allToplineBannerRetailsMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allToplineBannerV2sMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ToplineBannerV2ModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allToplineBannerV2sMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allUiContentsMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (UiContentModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allUiContentsMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allUploadsMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { filter?: (UploadFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allUploadsMetaCms`+ (args ? '('+['filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allWebPromoBannersMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (WebPromoBannerModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allWebPromoBannersMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _allZonesMetaCms(builder: string | CollectionMetadataModelSelector | ((selector: CollectionMetadataModelSelector) => CollectionMetadataModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ZoneModelFilter | null), locale?: (SiteLocale | null) }) { return this.__child(`_allZonesMetaCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CollectionMetadataModelSelector, builder) }
  _siteCms(builder: string | SiteModelSelector | ((selector: SiteModelSelector) => SiteModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, locale?: (SiteLocale | null) }) { return this.__child(`_siteCms`+ (args ? '('+['fallbackLocales', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), SiteModelSelector, builder) }
  allAppActionsCms(builder: string | AppActionRecordModelSelector | ((selector: AppActionRecordModelSelector) => AppActionRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (AppActionModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (AppActionModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allAppActionsCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), AppActionRecordModelSelector, builder) }
  allAppContentsCms(builder: string | AppContentRecordModelSelector | ((selector: AppContentRecordModelSelector) => AppContentRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (AppContentModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (AppContentModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allAppContentsCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), AppContentRecordModelSelector, builder) }
  allAppDiscoverTilesCms(builder: string | AppDiscoverTileRecordModelSelector | ((selector: AppDiscoverTileRecordModelSelector) => AppDiscoverTileRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (AppDiscoverTileModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (AppDiscoverTileModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allAppDiscoverTilesCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), AppDiscoverTileRecordModelSelector, builder) }
  allAppFiltersCms(builder: string | AppFilterRecordModelSelector | ((selector: AppFilterRecordModelSelector) => AppFilterRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (AppFilterModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (AppFilterModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allAppFiltersCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), AppFilterRecordModelSelector, builder) }
  allAppOrderScreensCms(builder: string | AppOrderScreenRecordModelSelector | ((selector: AppOrderScreenRecordModelSelector) => AppOrderScreenRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (AppOrderScreenModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (AppOrderScreenModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allAppOrderScreensCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), AppOrderScreenRecordModelSelector, builder) }
  allAppTipsCms(builder: string | AppTipRecordModelSelector | ((selector: AppTipRecordModelSelector) => AppTipRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (AppTipModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (AppTipModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allAppTipsCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), AppTipRecordModelSelector, builder) }
  allBlogArticlesCms(builder: string | BlogArticleRecordModelSelector | ((selector: BlogArticleRecordModelSelector) => BlogArticleRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (BlogArticleModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (BlogArticleModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allBlogArticlesCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), BlogArticleRecordModelSelector, builder) }
  allBlogCategoriesCms(builder: string | BlogCategoryRecordModelSelector | ((selector: BlogCategoryRecordModelSelector) => BlogCategoryRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (BlogCategoryModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (BlogCategoryModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allBlogCategoriesCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), BlogCategoryRecordModelSelector, builder) }
  allDownloadAppsCms(builder: string | DownloadAppRecordModelSelector | ((selector: DownloadAppRecordModelSelector) => DownloadAppRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (DownloadAppModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (DownloadAppModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allDownloadAppsCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), DownloadAppRecordModelSelector, builder) }
  allDynamicLandingPageCampaignsCms(builder: string | DynamicLandingPageCampaignRecordModelSelector | ((selector: DynamicLandingPageCampaignRecordModelSelector) => DynamicLandingPageCampaignRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (DynamicLandingPageCampaignModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (DynamicLandingPageCampaignModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allDynamicLandingPageCampaignsCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), DynamicLandingPageCampaignRecordModelSelector, builder) }
  allFiltersCms(builder: string | FilterRecordModelSelector | ((selector: FilterRecordModelSelector) => FilterRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (FilterModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (FilterModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allFiltersCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), FilterRecordModelSelector, builder) }
  allGeneralPagesCms(builder: string | GeneralPageRecordModelSelector | ((selector: GeneralPageRecordModelSelector) => GeneralPageRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (GeneralPageModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (GeneralPageModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allGeneralPagesCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), GeneralPageRecordModelSelector, builder) }
  allGoalBasedPlansCms(builder: string | GoalBasedPlanRecordModelSelector | ((selector: GoalBasedPlanRecordModelSelector) => GoalBasedPlanRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (GoalBasedPlanModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (GoalBasedPlanModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allGoalBasedPlansCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), GoalBasedPlanRecordModelSelector, builder) }
  allGoalBasedQuizzesCms(builder: string | GoalBasedQuizRecordModelSelector | ((selector: GoalBasedQuizRecordModelSelector) => GoalBasedQuizRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (GoalBasedQuizModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (GoalBasedQuizModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allGoalBasedQuizzesCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), GoalBasedQuizRecordModelSelector, builder) }
  allLandingPageV2sCms(builder: string | LandingPageV2RecordModelSelector | ((selector: LandingPageV2RecordModelSelector) => LandingPageV2RecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (LandingPageV2ModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (LandingPageV2ModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allLandingPageV2sCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), LandingPageV2RecordModelSelector, builder) }
  allLandingPagesCms(builder: string | LandingPageRecordModelSelector | ((selector: LandingPageRecordModelSelector) => LandingPageRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (LandingPageModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (LandingPageModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allLandingPagesCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), LandingPageRecordModelSelector, builder) }
  allMealPlansCms(builder: string | MealPlanRecordModelSelector | ((selector: MealPlanRecordModelSelector) => MealPlanRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (MealPlanModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (MealPlanModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allMealPlansCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), MealPlanRecordModelSelector, builder) }
  allMenuHierarchiesCms(builder: string | MenuHierarchyRecordModelSelector | ((selector: MenuHierarchyRecordModelSelector) => MenuHierarchyRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (MenuHierarchyModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (MenuHierarchyModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allMenuHierarchiesCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), MenuHierarchyRecordModelSelector, builder) }
  allMenuPageSeosCms(builder: string | MenuPageSeoRecordModelSelector | ((selector: MenuPageSeoRecordModelSelector) => MenuPageSeoRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (MenuPageSeoModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (MenuPageSeoModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allMenuPageSeosCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), MenuPageSeoRecordModelSelector, builder) }
  allMenuSortOptionsCms(builder: string | MenuSortOptionRecordModelSelector | ((selector: MenuSortOptionRecordModelSelector) => MenuSortOptionRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (MenuSortOptionModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (MenuSortOptionModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allMenuSortOptionsCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), MenuSortOptionRecordModelSelector, builder) }
  allOrderPlanCategoriesCms(builder: string | OrderPlanCategoryRecordModelSelector | ((selector: OrderPlanCategoryRecordModelSelector) => OrderPlanCategoryRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (OrderPlanCategoryModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (OrderPlanCategoryModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allOrderPlanCategoriesCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), OrderPlanCategoryRecordModelSelector, builder) }
  allPricingGroupsCms(builder: string | PricingGroupRecordModelSelector | ((selector: PricingGroupRecordModelSelector) => PricingGroupRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (PricingGroupModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (PricingGroupModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allPricingGroupsCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), PricingGroupRecordModelSelector, builder) }
  allProductCategoriesCms(builder: string | ProductCategoryRecordModelSelector | ((selector: ProductCategoryRecordModelSelector) => ProductCategoryRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ProductCategoryModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (ProductCategoryModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allProductCategoriesCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), ProductCategoryRecordModelSelector, builder) }
  allProductCategoryAppsCms(builder: string | ProductCategoryAppRecordModelSelector | ((selector: ProductCategoryAppRecordModelSelector) => ProductCategoryAppRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ProductCategoryAppModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (ProductCategoryAppModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allProductCategoryAppsCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), ProductCategoryAppRecordModelSelector, builder) }
  allProductDietaryTagsCms(builder: string | ProductDietaryTagRecordModelSelector | ((selector: ProductDietaryTagRecordModelSelector) => ProductDietaryTagRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ProductDietaryTagModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (ProductDietaryTagModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allProductDietaryTagsCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), ProductDietaryTagRecordModelSelector, builder) }
  allProductInstructionsCms(builder: string | ProductInstructionRecordModelSelector | ((selector: ProductInstructionRecordModelSelector) => ProductInstructionRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ProductInstructionModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (ProductInstructionModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allProductInstructionsCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), ProductInstructionRecordModelSelector, builder) }
  allProductPrimaryTagsCms(builder: string | ProductPrimaryTagRecordModelSelector | ((selector: ProductPrimaryTagRecordModelSelector) => ProductPrimaryTagRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ProductPrimaryTagModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (ProductPrimaryTagModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allProductPrimaryTagsCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), ProductPrimaryTagRecordModelSelector, builder) }
  allProductRestrictionLimitsCms(builder: string | ProductRestrictionLimitRecordModelSelector | ((selector: ProductRestrictionLimitRecordModelSelector) => ProductRestrictionLimitRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ProductRestrictionLimitModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (ProductRestrictionLimitModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allProductRestrictionLimitsCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), ProductRestrictionLimitRecordModelSelector, builder) }
  allProductSecondaryTagsCms(builder: string | ProductSecondaryTagRecordModelSelector | ((selector: ProductSecondaryTagRecordModelSelector) => ProductSecondaryTagRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ProductSecondaryTagModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (ProductSecondaryTagModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allProductSecondaryTagsCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), ProductSecondaryTagRecordModelSelector, builder) }
  allProductSubcategoriesCms(builder: string | ProductSubcategoryRecordModelSelector | ((selector: ProductSubcategoryRecordModelSelector) => ProductSubcategoryRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ProductSubcategoryModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (ProductSubcategoryModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allProductSubcategoriesCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), ProductSubcategoryRecordModelSelector, builder) }
  allProductTagsCms(builder: string | ProductTagRecordModelSelector | ((selector: ProductTagRecordModelSelector) => ProductTagRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ProductTagModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (ProductTagModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allProductTagsCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), ProductTagRecordModelSelector, builder) }
  allProductTertiaryTagsCms(builder: string | ProductTertiaryTagRecordModelSelector | ((selector: ProductTertiaryTagRecordModelSelector) => ProductTertiaryTagRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ProductTertiaryTagModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (ProductTertiaryTagModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allProductTertiaryTagsCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), ProductTertiaryTagRecordModelSelector, builder) }
  allProductsCms(builder: string | ProductRecordModelSelector | ((selector: ProductRecordModelSelector) => ProductRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ProductModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (ProductModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allProductsCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), ProductRecordModelSelector, builder) }
  allProfileStagesCms(builder: string | ProfileStageRecordModelSelector | ((selector: ProfileStageRecordModelSelector) => ProfileStageRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ProfileStageModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (ProfileStageModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allProfileStagesCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), ProfileStageRecordModelSelector, builder) }
  allReasonsCms(builder: string | ReasonRecordModelSelector | ((selector: ReasonRecordModelSelector) => ReasonRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ReasonModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (ReasonModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allReasonsCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), ReasonRecordModelSelector, builder) }
  allRetailMenuHierarchiesCms(builder: string | RetailMenuHierarchyRecordModelSelector | ((selector: RetailMenuHierarchyRecordModelSelector) => RetailMenuHierarchyRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (RetailMenuHierarchyModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (RetailMenuHierarchyModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allRetailMenuHierarchiesCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), RetailMenuHierarchyRecordModelSelector, builder) }
  allRetailPagesCms(builder: string | RetailPageRecordModelSelector | ((selector: RetailPageRecordModelSelector) => RetailPageRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (RetailPageModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (RetailPageModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allRetailPagesCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), RetailPageRecordModelSelector, builder) }
  allSiteContentsCms(builder: string | SiteContentRecordModelSelector | ((selector: SiteContentRecordModelSelector) => SiteContentRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (SiteContentModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (SiteContentModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allSiteContentsCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), SiteContentRecordModelSelector, builder) }
  allSortMenusCms(builder: string | SortMenuRecordModelSelector | ((selector: SortMenuRecordModelSelector) => SortMenuRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (SortMenuModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (SortMenuModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allSortMenusCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), SortMenuRecordModelSelector, builder) }
  allSubscriptionInstructionsCms(builder: string | SubscriptionInstructionRecordModelSelector | ((selector: SubscriptionInstructionRecordModelSelector) => SubscriptionInstructionRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (SubscriptionInstructionModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (SubscriptionInstructionModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allSubscriptionInstructionsCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), SubscriptionInstructionRecordModelSelector, builder) }
  allSvgTestsCms(builder: string | SvgTestRecordModelSelector | ((selector: SvgTestRecordModelSelector) => SvgTestRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (SvgTestModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (SvgTestModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allSvgTestsCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), SvgTestRecordModelSelector, builder) }
  allSwapConfigsCms(builder: string | SwapConfigRecordModelSelector | ((selector: SwapConfigRecordModelSelector) => SwapConfigRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (SwapConfigModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (SwapConfigModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allSwapConfigsCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), SwapConfigRecordModelSelector, builder) }
  allToplineBannerRetailsCms(builder: string | ToplineBannerRetailRecordModelSelector | ((selector: ToplineBannerRetailRecordModelSelector) => ToplineBannerRetailRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ToplineBannerRetailModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (ToplineBannerRetailModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allToplineBannerRetailsCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), ToplineBannerRetailRecordModelSelector, builder) }
  allToplineBannerV2sCms(builder: string | ToplineBannerV2RecordModelSelector | ((selector: ToplineBannerV2RecordModelSelector) => ToplineBannerV2RecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ToplineBannerV2ModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (ToplineBannerV2ModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allToplineBannerV2sCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), ToplineBannerV2RecordModelSelector, builder) }
  allUiContentsCms(builder: string | UiContentRecordModelSelector | ((selector: UiContentRecordModelSelector) => UiContentRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (UiContentModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (UiContentModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allUiContentsCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), UiContentRecordModelSelector, builder) }
  allUploadsCms(builder: string | FileFieldModelSelector | ((selector: FileFieldModelSelector) => FileFieldModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (UploadFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (UploadOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allUploadsCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), FileFieldModelSelector, builder) }
  allWebPromoBannersCms(builder: string | WebPromoBannerRecordModelSelector | ((selector: WebPromoBannerRecordModelSelector) => WebPromoBannerRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (WebPromoBannerModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (WebPromoBannerModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allWebPromoBannersCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), WebPromoBannerRecordModelSelector, builder) }
  allZonesCms(builder: string | ZoneRecordModelSelector | ((selector: ZoneRecordModelSelector) => ZoneRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ZoneModelFilter | null), first?: (any | null), locale?: (SiteLocale | null), orderBy?: (ZoneModelOrderBy | null)[] | null, skip?: (any | null) }) { return this.__child(`allZonesCms`+ (args ? '('+['fallbackLocales', 'filter', 'first', 'locale', 'orderBy', 'skip'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), ZoneRecordModelSelector, builder) }
  appActionCms(builder: string | AppActionRecordModelSelector | ((selector: AppActionRecordModelSelector) => AppActionRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (AppActionModelFilter | null), locale?: (SiteLocale | null), orderBy?: (AppActionModelOrderBy | null)[] | null }) { return this.__child(`appActionCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), AppActionRecordModelSelector, builder) }
  appContent(builder: string | AppContentModelSelector | ((selector: AppContentModelSelector) => AppContentModelSelector) | undefined, args: { subType?: (ContentSubType | null), type: ContentType }) { return this.__child(`appContent`+ (args ? '('+['subType', 'type'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), AppContentModelSelector, builder) }
  appContentCms(builder: string | AppContentRecordModelSelector | ((selector: AppContentRecordModelSelector) => AppContentRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (AppContentModelFilter | null), locale?: (SiteLocale | null), orderBy?: (AppContentModelOrderBy | null)[] | null }) { return this.__child(`appContentCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), AppContentRecordModelSelector, builder) }
  appDiscoverTileCms(builder: string | AppDiscoverTileRecordModelSelector | ((selector: AppDiscoverTileRecordModelSelector) => AppDiscoverTileRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (AppDiscoverTileModelFilter | null), locale?: (SiteLocale | null), orderBy?: (AppDiscoverTileModelOrderBy | null)[] | null }) { return this.__child(`appDiscoverTileCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), AppDiscoverTileRecordModelSelector, builder) }
  appFilterCms(builder: string | AppFilterRecordModelSelector | ((selector: AppFilterRecordModelSelector) => AppFilterRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (AppFilterModelFilter | null), locale?: (SiteLocale | null), orderBy?: (AppFilterModelOrderBy | null)[] | null }) { return this.__child(`appFilterCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), AppFilterRecordModelSelector, builder) }
  appOrderScreenCms(builder: string | AppOrderScreenRecordModelSelector | ((selector: AppOrderScreenRecordModelSelector) => AppOrderScreenRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (AppOrderScreenModelFilter | null), locale?: (SiteLocale | null), orderBy?: (AppOrderScreenModelOrderBy | null)[] | null }) { return this.__child(`appOrderScreenCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), AppOrderScreenRecordModelSelector, builder) }
  appTipCms(builder: string | AppTipRecordModelSelector | ((selector: AppTipRecordModelSelector) => AppTipRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (AppTipModelFilter | null), locale?: (SiteLocale | null), orderBy?: (AppTipModelOrderBy | null)[] | null }) { return this.__child(`appTipCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), AppTipRecordModelSelector, builder) }
  basket(builder: string | BasketRtoModelSelector | ((selector: BasketRtoModelSelector) => BasketRtoModelSelector) | undefined, args: { cart: CartInput, coupon?: (string | null) }) { return this.__child(`basket`+ (args ? '('+['cart', 'coupon'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), BasketRtoModelSelector, builder) }
  blogArticleCms(builder: string | BlogArticleRecordModelSelector | ((selector: BlogArticleRecordModelSelector) => BlogArticleRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (BlogArticleModelFilter | null), locale?: (SiteLocale | null), orderBy?: (BlogArticleModelOrderBy | null)[] | null }) { return this.__child(`blogArticleCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), BlogArticleRecordModelSelector, builder) }
  blogCategoryCms(builder: string | BlogCategoryRecordModelSelector | ((selector: BlogCategoryRecordModelSelector) => BlogCategoryRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (BlogCategoryModelFilter | null), locale?: (SiteLocale | null), orderBy?: (BlogCategoryModelOrderBy | null)[] | null }) { return this.__child(`blogCategoryCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), BlogCategoryRecordModelSelector, builder) }
  cart(builder: string | CartModelSelector | ((selector: CartModelSelector) => CartModelSelector) | undefined) { return this.__child(`cart`, CartModelSelector, builder) }
  clientToken(builder: string | TokenRtoModelSelector | ((selector: TokenRtoModelSelector) => TokenRtoModelSelector) | undefined) { return this.__child(`clientToken`, TokenRtoModelSelector, builder) }
  customer(builder: string | CustomerProfileModelSelector | ((selector: CustomerProfileModelSelector) => CustomerProfileModelSelector) | undefined) { return this.__child(`customer`, CustomerProfileModelSelector, builder) }
  customerActivities(builder: string | CustomerActivityModelSelector | ((selector: CustomerActivityModelSelector) => CustomerActivityModelSelector) | undefined, args?: { limit?: (number | null), offset?: (number | null), sort?: CustomerActivitySortInput[] | null, where?: (CustomerActivityWhereInput | null) }) { return this.__child(`customerActivities`+ (args ? '('+['limit', 'offset', 'sort', 'where'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CustomerActivityModelSelector, builder) }
  customerActivity(builder: string | CustomerActivityModelSelector | ((selector: CustomerActivityModelSelector) => CustomerActivityModelSelector) | undefined, args: { id: string }) { return this.__child(`customerActivity`+ (args ? '('+['id'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CustomerActivityModelSelector, builder) }
  customerInfo(builder: string | CustomerInfoModelSelector | ((selector: CustomerInfoModelSelector) => CustomerInfoModelSelector) | undefined) { return this.__child(`customerInfo`, CustomerInfoModelSelector, builder) }
  dashboard(builder: string | DashboardModelSelector | ((selector: DashboardModelSelector) => DashboardModelSelector) | undefined, args?: { date?: (any | null), timezone?: (string | null) }) { return this.__child(`dashboard`+ (args ? '('+['date', 'timezone'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), DashboardModelSelector, builder) }
  dashboardBanner(builder: string | DashboardBannerModelSelector | ((selector: DashboardBannerModelSelector) => DashboardBannerModelSelector) | undefined, args?: { date?: (any | null), timezone?: (string | null) }) { return this.__child(`dashboardBanner`+ (args ? '('+['date', 'timezone'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), DashboardBannerModelSelector, builder) }
  deliveryInfo(builder: string | DeliveryInfoModelSelector | ((selector: DeliveryInfoModelSelector) => DeliveryInfoModelSelector) | undefined, args: { date?: (any | null), disableExpress?: (boolean | null), lookup: string, maxDaysInFuture?: (number | null), maxDeliveryDayCount?: (number | null), skipHolidays?: (boolean | null), suburb?: (string | null), type?: (DeliveryInfoLookupType | null) }) { return this.__child(`deliveryInfo`+ (args ? '('+['date', 'disableExpress', 'lookup', 'maxDaysInFuture', 'maxDeliveryDayCount', 'skipHolidays', 'suburb', 'type'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), DeliveryInfoModelSelector, builder) }
  downloadAppCms(builder: string | DownloadAppRecordModelSelector | ((selector: DownloadAppRecordModelSelector) => DownloadAppRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (DownloadAppModelFilter | null), locale?: (SiteLocale | null), orderBy?: (DownloadAppModelOrderBy | null)[] | null }) { return this.__child(`downloadAppCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), DownloadAppRecordModelSelector, builder) }
  dynamicLandingPageCampaignCms(builder: string | DynamicLandingPageCampaignRecordModelSelector | ((selector: DynamicLandingPageCampaignRecordModelSelector) => DynamicLandingPageCampaignRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (DynamicLandingPageCampaignModelFilter | null), locale?: (SiteLocale | null), orderBy?: (DynamicLandingPageCampaignModelOrderBy | null)[] | null }) { return this.__child(`dynamicLandingPageCampaignCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), DynamicLandingPageCampaignRecordModelSelector, builder) }
  filterCms(builder: string | FilterRecordModelSelector | ((selector: FilterRecordModelSelector) => FilterRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (FilterModelFilter | null), locale?: (SiteLocale | null), orderBy?: (FilterModelOrderBy | null)[] | null }) { return this.__child(`filterCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), FilterRecordModelSelector, builder) }
  footerCms(builder: string | FooterRecordModelSelector | ((selector: FooterRecordModelSelector) => FooterRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, locale?: (SiteLocale | null) }) { return this.__child(`footerCms`+ (args ? '('+['fallbackLocales', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), FooterRecordModelSelector, builder) }
  generalPageCms(builder: string | GeneralPageRecordModelSelector | ((selector: GeneralPageRecordModelSelector) => GeneralPageRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (GeneralPageModelFilter | null), locale?: (SiteLocale | null), orderBy?: (GeneralPageModelOrderBy | null)[] | null }) { return this.__child(`generalPageCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), GeneralPageRecordModelSelector, builder) }
  generateIdentifierCode(builder: string | MessageRtoModelSelector | ((selector: MessageRtoModelSelector) => MessageRtoModelSelector) | undefined, args: { input: PrepareCustomerIdentifierCodeInput }) { return this.__child(`generateIdentifierCode`+ (args ? '('+['input'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), MessageRtoModelSelector, builder) }
  giftCard(builder: string | GiftCardModelSelector | ((selector: GiftCardModelSelector) => GiftCardModelSelector) | undefined, args: { code: string }) { return this.__child(`giftCard`+ (args ? '('+['code'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), GiftCardModelSelector, builder) }
  goalBasedPlanCms(builder: string | GoalBasedPlanRecordModelSelector | ((selector: GoalBasedPlanRecordModelSelector) => GoalBasedPlanRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (GoalBasedPlanModelFilter | null), locale?: (SiteLocale | null), orderBy?: (GoalBasedPlanModelOrderBy | null)[] | null }) { return this.__child(`goalBasedPlanCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), GoalBasedPlanRecordModelSelector, builder) }
  goalBasedPlans(builder: string | GoalBasedPlanRtoModelSelector | ((selector: GoalBasedPlanRtoModelSelector) => GoalBasedPlanRtoModelSelector) | undefined, args: { ageRange: AgeRanges, bucket?: (number | null), calorie?: (number | null), gender: Gender, goal: Goal, key?: (string | null), numberOfDays?: (number | null), weight?: (number | null) }) { return this.__child(`goalBasedPlans`+ (args ? '('+['ageRange', 'bucket', 'calorie', 'gender', 'goal', 'key', 'numberOfDays', 'weight'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), GoalBasedPlanRtoModelSelector, builder) }
  goalBasedQuizCms(builder: string | GoalBasedQuizRecordModelSelector | ((selector: GoalBasedQuizRecordModelSelector) => GoalBasedQuizRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (GoalBasedQuizModelFilter | null), locale?: (SiteLocale | null), orderBy?: (GoalBasedQuizModelOrderBy | null)[] | null }) { return this.__child(`goalBasedQuizCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), GoalBasedQuizRecordModelSelector, builder) }
  guestCart(builder: string | GuestCartModelSelector | ((selector: GuestCartModelSelector) => GuestCartModelSelector) | undefined, args: { cart: GuestCartInput, limit?: (number | null) }) { return this.__child(`guestCart`+ (args ? '('+['cart', 'limit'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), GuestCartModelSelector, builder) }
  initiateEmailChange(builder: string | CustomerIdentifierQuestionsOutputModelSelector | ((selector: CustomerIdentifierQuestionsOutputModelSelector) => CustomerIdentifierQuestionsOutputModelSelector) | undefined, args: { input: CustomerIdentifierInput }) { return this.__child(`initiateEmailChange`+ (args ? '('+['input'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CustomerIdentifierQuestionsOutputModelSelector, builder) }
  landingPageCms(builder: string | LandingPageRecordModelSelector | ((selector: LandingPageRecordModelSelector) => LandingPageRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (LandingPageModelFilter | null), locale?: (SiteLocale | null), orderBy?: (LandingPageModelOrderBy | null)[] | null }) { return this.__child(`landingPageCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), LandingPageRecordModelSelector, builder) }
  landingPageV2Cms(builder: string | LandingPageV2RecordModelSelector | ((selector: LandingPageV2RecordModelSelector) => LandingPageV2RecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (LandingPageV2ModelFilter | null), locale?: (SiteLocale | null), orderBy?: (LandingPageV2ModelOrderBy | null)[] | null }) { return this.__child(`landingPageV2Cms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), LandingPageV2RecordModelSelector, builder) }
  mealPlan(builder: string | MealPlanModelSelector | ((selector: MealPlanModelSelector) => MealPlanModelSelector) | undefined, args: { key: string }) { return this.__child(`mealPlan`+ (args ? '('+['key'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), MealPlanModelSelector, builder) }
  mealPlanCms(builder: string | MealPlanRecordModelSelector | ((selector: MealPlanRecordModelSelector) => MealPlanRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (MealPlanModelFilter | null), locale?: (SiteLocale | null), orderBy?: (MealPlanModelOrderBy | null)[] | null }) { return this.__child(`mealPlanCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), MealPlanRecordModelSelector, builder) }
  mealPlans(builder: string | MealPlanModelSelector | ((selector: MealPlanModelSelector) => MealPlanModelSelector) | undefined, args?: { limit?: (number | null), offset?: (number | null), sort?: MealPlanSortInput[] | null, where?: (MealPlanWhereInput | null) }) { return this.__child(`mealPlans`+ (args ? '('+['limit', 'offset', 'sort', 'where'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), MealPlanModelSelector, builder) }
  menuHierarchyCms(builder: string | MenuHierarchyRecordModelSelector | ((selector: MenuHierarchyRecordModelSelector) => MenuHierarchyRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (MenuHierarchyModelFilter | null), locale?: (SiteLocale | null), orderBy?: (MenuHierarchyModelOrderBy | null)[] | null }) { return this.__child(`menuHierarchyCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), MenuHierarchyRecordModelSelector, builder) }
  menuPageSeoCms(builder: string | MenuPageSeoRecordModelSelector | ((selector: MenuPageSeoRecordModelSelector) => MenuPageSeoRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (MenuPageSeoModelFilter | null), locale?: (SiteLocale | null), orderBy?: (MenuPageSeoModelOrderBy | null)[] | null }) { return this.__child(`menuPageSeoCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), MenuPageSeoRecordModelSelector, builder) }
  menuSortOptionCms(builder: string | MenuSortOptionRecordModelSelector | ((selector: MenuSortOptionRecordModelSelector) => MenuSortOptionRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (MenuSortOptionModelFilter | null), locale?: (SiteLocale | null), orderBy?: (MenuSortOptionModelOrderBy | null)[] | null }) { return this.__child(`menuSortOptionCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), MenuSortOptionRecordModelSelector, builder) }
  navbarCms(builder: string | NavbarRecordModelSelector | ((selector: NavbarRecordModelSelector) => NavbarRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, locale?: (SiteLocale | null) }) { return this.__child(`navbarCms`+ (args ? '('+['fallbackLocales', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), NavbarRecordModelSelector, builder) }
  order(builder: string | OrderModelSelector | ((selector: OrderModelSelector) => OrderModelSelector) | undefined, args: { email?: (string | null), lookup: string }) { return this.__child(`order`+ (args ? '('+['email', 'lookup'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), OrderModelSelector, builder) }
  orderDeliveryInfo(builder: string | OrderDeliveryInfoModelSelector | ((selector: OrderDeliveryInfoModelSelector) => OrderDeliveryInfoModelSelector) | undefined, args: { input: OrderDeliveryInfoInput }) { return this.__child(`orderDeliveryInfo`+ (args ? '('+['input'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), OrderDeliveryInfoModelSelector, builder) }
  orderPlanCategoryCms(builder: string | OrderPlanCategoryRecordModelSelector | ((selector: OrderPlanCategoryRecordModelSelector) => OrderPlanCategoryRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (OrderPlanCategoryModelFilter | null), locale?: (SiteLocale | null), orderBy?: (OrderPlanCategoryModelOrderBy | null)[] | null }) { return this.__child(`orderPlanCategoryCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), OrderPlanCategoryRecordModelSelector, builder) }
  orders(builder: string | OrderModelSelector | ((selector: OrderModelSelector) => OrderModelSelector) | undefined, args?: { limit?: (number | null), offset?: (number | null), sort?: OrderSortInput[] | null, where?: (OrderWhereInput | null) }) { return this.__child(`orders`+ (args ? '('+['limit', 'offset', 'sort', 'where'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), OrderModelSelector, builder) }
  paymentMethods(builder: string | CustomerPaymentModelSelector | ((selector: CustomerPaymentModelSelector) => CustomerPaymentModelSelector) | undefined) { return this.__child(`paymentMethods`, CustomerPaymentModelSelector, builder) }
  plans(builder: string | PlanMetaModelSelector | ((selector: PlanMetaModelSelector) => PlanMetaModelSelector) | undefined) { return this.__child(`plans`, PlanMetaModelSelector, builder) }
  pricingGroupCms(builder: string | PricingGroupRecordModelSelector | ((selector: PricingGroupRecordModelSelector) => PricingGroupRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (PricingGroupModelFilter | null), locale?: (SiteLocale | null), orderBy?: (PricingGroupModelOrderBy | null)[] | null }) { return this.__child(`pricingGroupCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), PricingGroupRecordModelSelector, builder) }
  product(builder: string | ProductModelSelector | ((selector: ProductModelSelector) => ProductModelSelector) | undefined, args: { sku: string }) { return this.__child(`product`+ (args ? '('+['sku'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), ProductModelSelector, builder) }
  productCategoryAppCms(builder: string | ProductCategoryAppRecordModelSelector | ((selector: ProductCategoryAppRecordModelSelector) => ProductCategoryAppRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ProductCategoryAppModelFilter | null), locale?: (SiteLocale | null), orderBy?: (ProductCategoryAppModelOrderBy | null)[] | null }) { return this.__child(`productCategoryAppCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), ProductCategoryAppRecordModelSelector, builder) }
  productCategoryCms(builder: string | ProductCategoryRecordModelSelector | ((selector: ProductCategoryRecordModelSelector) => ProductCategoryRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ProductCategoryModelFilter | null), locale?: (SiteLocale | null), orderBy?: (ProductCategoryModelOrderBy | null)[] | null }) { return this.__child(`productCategoryCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), ProductCategoryRecordModelSelector, builder) }
  productCms(builder: string | ProductRecordModelSelector | ((selector: ProductRecordModelSelector) => ProductRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ProductModelFilter | null), locale?: (SiteLocale | null), orderBy?: (ProductModelOrderBy | null)[] | null }) { return this.__child(`productCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), ProductRecordModelSelector, builder) }
  productCollection(builder: string | ProductCollectionDtoModelSelector | ((selector: ProductCollectionDtoModelSelector) => ProductCollectionDtoModelSelector) | undefined, args: { id: string }) { return this.__child(`productCollection`+ (args ? '('+['id'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), ProductCollectionDtoModelSelector, builder) }
  productCollections(builder: string | ProductCollectionDtoModelSelector | ((selector: ProductCollectionDtoModelSelector) => ProductCollectionDtoModelSelector) | undefined, args?: { limit?: (number | null), offset?: (number | null), sort?: ProductCollectionSortInput[] | null, where?: (ProductCollectionWhereInput | null) }) { return this.__child(`productCollections`+ (args ? '('+['limit', 'offset', 'sort', 'where'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), ProductCollectionDtoModelSelector, builder) }
  productDietaryTagCms(builder: string | ProductDietaryTagRecordModelSelector | ((selector: ProductDietaryTagRecordModelSelector) => ProductDietaryTagRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ProductDietaryTagModelFilter | null), locale?: (SiteLocale | null), orderBy?: (ProductDietaryTagModelOrderBy | null)[] | null }) { return this.__child(`productDietaryTagCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), ProductDietaryTagRecordModelSelector, builder) }
  productInstructionCms(builder: string | ProductInstructionRecordModelSelector | ((selector: ProductInstructionRecordModelSelector) => ProductInstructionRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ProductInstructionModelFilter | null), locale?: (SiteLocale | null), orderBy?: (ProductInstructionModelOrderBy | null)[] | null }) { return this.__child(`productInstructionCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), ProductInstructionRecordModelSelector, builder) }
  productPrimaryTagCms(builder: string | ProductPrimaryTagRecordModelSelector | ((selector: ProductPrimaryTagRecordModelSelector) => ProductPrimaryTagRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ProductPrimaryTagModelFilter | null), locale?: (SiteLocale | null), orderBy?: (ProductPrimaryTagModelOrderBy | null)[] | null }) { return this.__child(`productPrimaryTagCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), ProductPrimaryTagRecordModelSelector, builder) }
  productRecommendationCms(builder: string | ProductRecommendationRecordModelSelector | ((selector: ProductRecommendationRecordModelSelector) => ProductRecommendationRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, locale?: (SiteLocale | null) }) { return this.__child(`productRecommendationCms`+ (args ? '('+['fallbackLocales', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), ProductRecommendationRecordModelSelector, builder) }
  productRestrictionLimitCms(builder: string | ProductRestrictionLimitRecordModelSelector | ((selector: ProductRestrictionLimitRecordModelSelector) => ProductRestrictionLimitRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ProductRestrictionLimitModelFilter | null), locale?: (SiteLocale | null), orderBy?: (ProductRestrictionLimitModelOrderBy | null)[] | null }) { return this.__child(`productRestrictionLimitCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), ProductRestrictionLimitRecordModelSelector, builder) }
  productSecondaryTagCms(builder: string | ProductSecondaryTagRecordModelSelector | ((selector: ProductSecondaryTagRecordModelSelector) => ProductSecondaryTagRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ProductSecondaryTagModelFilter | null), locale?: (SiteLocale | null), orderBy?: (ProductSecondaryTagModelOrderBy | null)[] | null }) { return this.__child(`productSecondaryTagCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), ProductSecondaryTagRecordModelSelector, builder) }
  productSubcategoryCms(builder: string | ProductSubcategoryRecordModelSelector | ((selector: ProductSubcategoryRecordModelSelector) => ProductSubcategoryRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ProductSubcategoryModelFilter | null), locale?: (SiteLocale | null), orderBy?: (ProductSubcategoryModelOrderBy | null)[] | null }) { return this.__child(`productSubcategoryCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), ProductSubcategoryRecordModelSelector, builder) }
  productTagCms(builder: string | ProductTagRecordModelSelector | ((selector: ProductTagRecordModelSelector) => ProductTagRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ProductTagModelFilter | null), locale?: (SiteLocale | null), orderBy?: (ProductTagModelOrderBy | null)[] | null }) { return this.__child(`productTagCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), ProductTagRecordModelSelector, builder) }
  productTertiaryTagCms(builder: string | ProductTertiaryTagRecordModelSelector | ((selector: ProductTertiaryTagRecordModelSelector) => ProductTertiaryTagRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ProductTertiaryTagModelFilter | null), locale?: (SiteLocale | null), orderBy?: (ProductTertiaryTagModelOrderBy | null)[] | null }) { return this.__child(`productTertiaryTagCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), ProductTertiaryTagRecordModelSelector, builder) }
  products(builder: string | ProductModelSelector | ((selector: ProductModelSelector) => ProductModelSelector) | undefined, args?: { limit?: (number | null), offset?: (number | null), searchText?: (string | null), skipVisibleCheck?: (boolean | null), sort?: ProductSortInput[] | null, where?: (ProductWhereInput | null) }) { return this.__child(`products`+ (args ? '('+['limit', 'offset', 'searchText', 'skipVisibleCheck', 'sort', 'where'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), ProductModelSelector, builder) }
  profileStageCms(builder: string | ProfileStageRecordModelSelector | ((selector: ProfileStageRecordModelSelector) => ProfileStageRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ProfileStageModelFilter | null), locale?: (SiteLocale | null), orderBy?: (ProfileStageModelOrderBy | null)[] | null }) { return this.__child(`profileStageCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), ProfileStageRecordModelSelector, builder) }
  reasonCms(builder: string | ReasonRecordModelSelector | ((selector: ReasonRecordModelSelector) => ReasonRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ReasonModelFilter | null), locale?: (SiteLocale | null), orderBy?: (ReasonModelOrderBy | null)[] | null }) { return this.__child(`reasonCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), ReasonRecordModelSelector, builder) }
  reorder(builder: string | ReorderRtoModelSelector | ((selector: ReorderRtoModelSelector) => ReorderRtoModelSelector) | undefined, args: { lookup: string }) { return this.__child(`reorder`+ (args ? '('+['lookup'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), ReorderRtoModelSelector, builder) }
  retailMenuHierarchyCms(builder: string | RetailMenuHierarchyRecordModelSelector | ((selector: RetailMenuHierarchyRecordModelSelector) => RetailMenuHierarchyRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (RetailMenuHierarchyModelFilter | null), locale?: (SiteLocale | null), orderBy?: (RetailMenuHierarchyModelOrderBy | null)[] | null }) { return this.__child(`retailMenuHierarchyCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), RetailMenuHierarchyRecordModelSelector, builder) }
  retailNavbarCms(builder: string | RetailNavbarRecordModelSelector | ((selector: RetailNavbarRecordModelSelector) => RetailNavbarRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, locale?: (SiteLocale | null) }) { return this.__child(`retailNavbarCms`+ (args ? '('+['fallbackLocales', 'locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), RetailNavbarRecordModelSelector, builder) }
  retailPageCms(builder: string | RetailPageRecordModelSelector | ((selector: RetailPageRecordModelSelector) => RetailPageRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (RetailPageModelFilter | null), locale?: (SiteLocale | null), orderBy?: (RetailPageModelOrderBy | null)[] | null }) { return this.__child(`retailPageCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), RetailPageRecordModelSelector, builder) }
  siteContentCms(builder: string | SiteContentRecordModelSelector | ((selector: SiteContentRecordModelSelector) => SiteContentRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (SiteContentModelFilter | null), locale?: (SiteLocale | null), orderBy?: (SiteContentModelOrderBy | null)[] | null }) { return this.__child(`siteContentCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), SiteContentRecordModelSelector, builder) }
  sortMenuCms(builder: string | SortMenuRecordModelSelector | ((selector: SortMenuRecordModelSelector) => SortMenuRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (SortMenuModelFilter | null), locale?: (SiteLocale | null), orderBy?: (SortMenuModelOrderBy | null)[] | null }) { return this.__child(`sortMenuCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), SortMenuRecordModelSelector, builder) }
  subscription(builder: string | SubscriptionEntityModelSelector | ((selector: SubscriptionEntityModelSelector) => SubscriptionEntityModelSelector) | undefined) { return this.__child(`subscription`, SubscriptionEntityModelSelector, builder) }
  subscriptionDeliveryInfo(builder: string | DeliveryInfoModelSelector | ((selector: DeliveryInfoModelSelector) => DeliveryInfoModelSelector) | undefined) { return this.__child(`subscriptionDeliveryInfo`, DeliveryInfoModelSelector, builder) }
  subscriptionInstructionCms(builder: string | SubscriptionInstructionRecordModelSelector | ((selector: SubscriptionInstructionRecordModelSelector) => SubscriptionInstructionRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (SubscriptionInstructionModelFilter | null), locale?: (SiteLocale | null), orderBy?: (SubscriptionInstructionModelOrderBy | null)[] | null }) { return this.__child(`subscriptionInstructionCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), SubscriptionInstructionRecordModelSelector, builder) }
  svgTestCms(builder: string | SvgTestRecordModelSelector | ((selector: SvgTestRecordModelSelector) => SvgTestRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (SvgTestModelFilter | null), locale?: (SiteLocale | null), orderBy?: (SvgTestModelOrderBy | null)[] | null }) { return this.__child(`svgTestCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), SvgTestRecordModelSelector, builder) }
  swapConfigCms(builder: string | SwapConfigRecordModelSelector | ((selector: SwapConfigRecordModelSelector) => SwapConfigRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (SwapConfigModelFilter | null), locale?: (SiteLocale | null), orderBy?: (SwapConfigModelOrderBy | null)[] | null }) { return this.__child(`swapConfigCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), SwapConfigRecordModelSelector, builder) }
  tokenizationKeys(builder: string | TokenRtoModelSelector | ((selector: TokenRtoModelSelector) => TokenRtoModelSelector) | undefined) { return this.__child(`tokenizationKeys`, TokenRtoModelSelector, builder) }
  toplineBannerRetailCms(builder: string | ToplineBannerRetailRecordModelSelector | ((selector: ToplineBannerRetailRecordModelSelector) => ToplineBannerRetailRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ToplineBannerRetailModelFilter | null), locale?: (SiteLocale | null), orderBy?: (ToplineBannerRetailModelOrderBy | null)[] | null }) { return this.__child(`toplineBannerRetailCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), ToplineBannerRetailRecordModelSelector, builder) }
  toplineBannerV2Cms(builder: string | ToplineBannerV2RecordModelSelector | ((selector: ToplineBannerV2RecordModelSelector) => ToplineBannerV2RecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ToplineBannerV2ModelFilter | null), locale?: (SiteLocale | null), orderBy?: (ToplineBannerV2ModelOrderBy | null)[] | null }) { return this.__child(`toplineBannerV2Cms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), ToplineBannerV2RecordModelSelector, builder) }
  uiContentCms(builder: string | UiContentRecordModelSelector | ((selector: UiContentRecordModelSelector) => UiContentRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (UiContentModelFilter | null), locale?: (SiteLocale | null), orderBy?: (UiContentModelOrderBy | null)[] | null }) { return this.__child(`uiContentCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), UiContentRecordModelSelector, builder) }
  uploadCms(builder: string | FileFieldModelSelector | ((selector: FileFieldModelSelector) => FileFieldModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (UploadFilter | null), locale?: (SiteLocale | null), orderBy?: (UploadOrderBy | null)[] | null }) { return this.__child(`uploadCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), FileFieldModelSelector, builder) }
  validateCustomerProfileStage(builder: string | MessageRtoModelSelector | ((selector: MessageRtoModelSelector) => MessageRtoModelSelector) | undefined, args: { input: VerifyCustomerProfileStageInput }) { return this.__child(`validateCustomerProfileStage`+ (args ? '('+['input'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), MessageRtoModelSelector, builder) }
  validateIdentifierQuestions(builder: string | CustomerIdentifierQuestionsOutputModelSelector | ((selector: CustomerIdentifierQuestionsOutputModelSelector) => CustomerIdentifierQuestionsOutputModelSelector) | undefined, args: { input: CustomerIdentifierAnswersInput }) { return this.__child(`validateIdentifierQuestions`+ (args ? '('+['input'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), CustomerIdentifierQuestionsOutputModelSelector, builder) }
  webPromoBannerCms(builder: string | WebPromoBannerRecordModelSelector | ((selector: WebPromoBannerRecordModelSelector) => WebPromoBannerRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (WebPromoBannerModelFilter | null), locale?: (SiteLocale | null), orderBy?: (WebPromoBannerModelOrderBy | null)[] | null }) { return this.__child(`webPromoBannerCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), WebPromoBannerRecordModelSelector, builder) }
  weeklyPlan(builder: string | WeeklyPlanRtoModelSelector | ((selector: WeeklyPlanRtoModelSelector) => WeeklyPlanRtoModelSelector) | undefined, args: { id: string }) { return this.__child(`weeklyPlan`+ (args ? '('+['id'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), WeeklyPlanRtoModelSelector, builder) }
  weeklyPlans(builder: string | WeeklyPlanRtoModelSelector | ((selector: WeeklyPlanRtoModelSelector) => WeeklyPlanRtoModelSelector) | undefined, args?: { limit?: (number | null), offset?: (number | null), sort?: WeeklyPlanSortInput[] | null, where?: (WeeklyPlanWhereInput | null) }) { return this.__child(`weeklyPlans`+ (args ? '('+['limit', 'offset', 'sort', 'where'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), WeeklyPlanRtoModelSelector, builder) }
  wellness(builder: string | WellnessRtoModelSelector | ((selector: WellnessRtoModelSelector) => WellnessRtoModelSelector) | undefined, args: { exercisingMinutes: number, sleepHours: number, wellbeingScore: number }) { return this.__child(`wellness`+ (args ? '('+['exercisingMinutes', 'sleepHours', 'wellbeingScore'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), WellnessRtoModelSelector, builder) }
  wlpMealPlan(builder: string | WlpMealPlansRtoModelSelector | ((selector: WlpMealPlansRtoModelSelector) => WlpMealPlansRtoModelSelector) | undefined, args: { activeLevels?: number[] | null, date?: (any | null), gender: Gender, sort?: WlpMealPlanSortInput[] | null, type: MealPlanSubCategory, weight?: (number | null) }) { return this.__child(`wlpMealPlan`+ (args ? '('+['activeLevels', 'date', 'gender', 'sort', 'type', 'weight'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), WlpMealPlansRtoModelSelector, builder) }
  zipCheckouts(builder: string | ZipCheckoutsRtoModelSelector | ((selector: ZipCheckoutsRtoModelSelector) => ZipCheckoutsRtoModelSelector) | undefined, args: { input: ZipCheckoutsDto }) { return this.__child(`zipCheckouts`+ (args ? '('+['input'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), ZipCheckoutsRtoModelSelector, builder) }
  zoneCms(builder: string | ZoneRecordModelSelector | ((selector: ZoneRecordModelSelector) => ZoneRecordModelSelector) | undefined, args?: { fallbackLocales?: SiteLocale[] | null, filter?: (ZoneModelFilter | null), locale?: (SiteLocale | null), orderBy?: (ZoneModelOrderBy | null)[] | null }) { return this.__child(`zoneCms`+ (args ? '('+['fallbackLocales', 'filter', 'locale', 'orderBy'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), ZoneRecordModelSelector, builder) }
}
export function selectFromQueryRoot() {
  return new QueryRootModelSelector()
}

export const queryRootModelPrimitives = selectFromQueryRoot().youtubeVideos
