import { PopupButton } from '@typeform/embed-react';
import Image from 'next/legacy/image';
import Link from 'next/link';
import type { RefObject } from 'react';
import React from 'react';

import TypeFormButton from '../TypeFormButton/TypeFormButton';

export interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  theme?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'quaternary'
    | 'quinary'
    | 'senary'
    | 'septenary'
    | 'custom'
    | 'wlp'
    | 'calorie'
    | 'muscle'
    | 'tofu'
    | 'mealPlan'
    | 'disabled'
    | 'red';
  link?: string;
  typeFormId?: string;
  type?: 'button' | 'submit' | 'reset';
  onClick?: () => void;
  ref?:
    | RefObject<HTMLButtonElement>
    | ((instance: HTMLButtonElement | null) => void)
    | null
    | undefined;
}

type Themes = {
  primary: string;
  secondary: string;
  tertiary: string;
  custom: string;
  wlp: string;
  calorie: string;
  muscle: string;
  quaternary: string;
  quinary: string;
  senary: string;
  septenary: string;
  mealPlan: string;
  disabled: string;
  red: string;
  [key: string]: string;
};

const themes: Themes = {
  primary:
    'text-white cursor-pointer bg-gradient-to-r from-primary to-primary2 hover:from-primary hover:to-primary',
  secondary: 'bg-secondary hover:bg-secondary2 text-rhubarb cursor-pointer',
  tertiary: 'hover:bg-marble text-black border border-black cursor-pointer',
  custom: 'bg-plan-custom text-black cursor-pointer',
  wlp: 'bg-plan-wlp text-black cursor-pointer',
  calorie: 'bg-plan-calorie text-black cursor-pointer',
  muscle: 'bg-plan-muscle text-black cursor-pointer',
  disabled: 'cursor-not-allowed bg-grey text-grey-dark',
  tofu: 'rounded-32 bg-tofu-dark',
  quaternary: 'text-black cursor-pointer',
  quinary:
    'text-black cursor-pointer bg-white border border-chef-lite hover:text-chef-red hover:bg-[#F7EEEB] active:text-black active:border-black active:bg-white',
  senary:
    'text-black cursor-pointer bg-white hover:text-chef-red hover:bg-[#F7EEEB] active:text-black active:bg-white',
  septenary:
    'text-white cursor-pointer bg-black hover:bg-gradient-to-r hover:from-black hover:to-[#383838] active:to-black',
  mealPlan: 'bg-black text-white cursor-pointer',
  red: 'bg-primary text-white',
};

const baseStyle =
  'rounded-full px-4 py-2 text-base font-interMedium flex items-center justify-center min-w-16';

type BaseButtonProps = Omit<ButtonProps, 'link'>;

const BaseButton = React.forwardRef<HTMLButtonElement, BaseButtonProps>(
  (
    {
      className,
      children,
      theme = 'primary',
      disabled = false,
      onClick,
      ...rest
    },
    ref
  ) => {
    const themeClass = disabled ? themes.disabled : themes[theme];
    const buttonClass = `${baseStyle} ${themeClass} ${className}`;

    return (
      <button
        className={buttonClass}
        disabled={disabled}
        onClick={onClick}
        ref={ref}
        {...rest}
      >
        {children}
      </button>
    );
  }
);

BaseButton.displayName = 'BaseButton';

const Button: React.FC<ButtonProps> = ({
  link,
  typeFormId,
  children,
  loading = false,
  ...rest
}: ButtonProps) => {
  if (link) {
    const isExternal = link.includes('https://');
    const parsedLink = link[0] === '/' ? link : `/${link}`;
    if (isExternal) {
      return (
        <BaseButton {...rest}>
          <a href={link} target="_blank" rel="noreferrer">
            {children}
          </a>
        </BaseButton>
      );
    }
    return (
      <Link href={parsedLink} legacyBehavior>
        <BaseButton {...rest}>{children}</BaseButton>
      </Link>
    );
  }
  if (typeFormId) {
    const theme = rest?.theme || 'primary';
    return (
      <PopupButton
        id={typeFormId}
        data-tf-opacity="100"
        data-tf-inline-on-mobile
        data-tf-iframe-props="title=My Muscle Chef"
        data-tf-transitive-search-params
        data-tf-auto-focus
        data-tf-medium="snippet"
      >
        <TypeFormButton theme={theme} {...rest}>
          {children}
        </TypeFormButton>
      </PopupButton>
    );
  }
  return (
    <BaseButton {...rest}>
      {loading && (
        <Image
          width={18}
          height={14}
          src="/assets/icons/checkout/Loading.svg"
          alt={''}
          className="mr-3 inline h-4 w-4 animate-spin"
        />
      )}
      {children}
    </BaseButton>
  );
};

export default Button;
