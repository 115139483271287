import { gql } from 'graphql-request';

export const CART_SELECTOR = gql`
 _id
billing {
  addressId
  method
  businessName
  country
  firstName
  instructions
  lastName
  postcode
  state
  street
  suburb
  zoneCode
}
message {
  message
  status
  title
  type
}
checkoutStartAt
customer {
  email
  firstName
  lastName
  phone
}
delivery {
  deliveryAddress {
    addressId
    method
    businessName
    country
    firstName
    lastName
    phone
    instructions
    postcode
    state
    street
    suburb
    zoneCode
  }
  deliveryDate
  timeSlot
}
frequency
giftCertificates {
  lineitemText
  netPrice
  recipientEmail
  recipientName
  senderName
}
plans {
  attributes {
    name
    value
  }
  category
  image
  lineItems {
    promotionTotal
    qty
    sku
    attributes {
      name
      value
    }
  }
  name
  netPrice
  planId
  subCategory
}
source
totals {
  itemTotal
  orderTotal
  shippingTotal
}
updatedAt
upsells
`;
