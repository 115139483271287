/* eslint-disable @typescript-eslint/naming-convention */
import * as _ from 'lodash';

import {
  formatAddressType,
  formatCartData,
  formatCheckoutData,
  formatFilters,
  formatLineItem,
  formatOrderData,
  formatOrderFailedData,
  formatProductData,
  formatProductType,
  formatSubscription,
  getEnumIndex,
} from './format-helpers';
import { trackEvent } from './gtm';
import {
  CheckoutCompletionType,
  CheckoutStep,
  TrackerEvent,
} from './tracker.constant';

export function trackCartViewed(cart: any, productsList?: any): void {
  const payload = formatCartData(cart, productsList);
  trackEvent(TrackerEvent.CartViewed, payload || {});
}

export function trackCheckoutStarted(cart: any, productsList?: any): void {
  const payload = formatCheckoutData(cart, productsList);
  trackEvent(TrackerEvent.CheckoutStarted, payload || {});
}

export function trackCheckoutStepCompleted(
  cart: any,
  step_name: CheckoutStep,
  completion_type: CheckoutCompletionType,
  payment_method?: any,
): void {
  const cart_id = cart.cartId;
  const step = getEnumIndex(CheckoutStep, step_name);
  const payload = {
    checkout_id: cart_id,
    cart_id,
    step,
    step_name,
    completion_type,
    payment_method,
  };
  trackEvent(TrackerEvent.CheckoutStepCompleted, payload || {});
}

export function trackCheckoutStepsPreFilled(cart: any) {
  _.forEach(CheckoutStep, (step) => {
    trackCheckoutStepCompleted(cart, step, CheckoutCompletionType.Prefill);
  });
}

const trackCheckoutStepViewed = (cart: any, step_name: CheckoutStep) => {
  const { cart_id } = formatCartData(cart);
  const step = getEnumIndex(CheckoutStep, step_name);
  const payload = {
    checkout_id: cart_id,
    cart_id,
    step,
    step_name,
  };
  trackEvent(TrackerEvent.CheckoutStepViewed, payload || {});
};

export function trackAllCheckoutStepsViewed(cart: any): void {
  _.forEach(CheckoutStep, (step) => {
    trackCheckoutStepViewed(cart, step);
  });
}

export function trackPaymentInfoEntered(
  cartId: string,
  payment_method: string,
) {
  const step_name = CheckoutStep.PaymentMethod;
  const step = getEnumIndex(CheckoutStep, step_name);
  const payload = {
    cart_id: cartId,
    checkout_id: cartId,
    step,
    payment_method,
  };
  trackEvent(TrackerEvent.PaymentInfoEntered, payload);
}

export function trackCouponEntered(
  cart_id: any,
  coupon: any,
  denied?: boolean,
): void {
  const payload = {
    cart_id,
    checkout_id: cart_id,
    coupon_name: denied ? coupon : coupon[0]?.code || coupon?.code,
  };
  trackEvent(TrackerEvent.CouponEntered, payload || {});
}

export function trackCouponApplied(cart_id: any, coupon: any): void {
  trackCouponEntered(cart_id, coupon);
  const payload = {
    cart_id,
    checkout_id: cart_id,
    coupon_id: coupon[0]?.id || coupon?.id,
    coupon_name: coupon[0]?.code || coupon?.code,
    discount: coupon[0]?.value || coupon?.value,
  };
  trackEvent(TrackerEvent.CouponApplied, payload || {});
}

export function trackCouponDenied(cart_id: any, coupon: any): void {
  trackCouponEntered(cart_id, coupon, true);
  const payload = {
    cart_id,
    checkout_id: cart_id,
    coupon_name: coupon,
  };
  trackEvent(TrackerEvent.CouponDenied, payload || {});
}

export function trackCouponRemoved(cart_id: any, coupon: any): void {
  const payload = {
    cart_id,
    checkout_id: cart_id,
    coupon_id: coupon?.promotionId ?? '',
    coupon_name: coupon?.code ?? '',
  };
  trackEvent(TrackerEvent.CouponRemoved, payload || {});
}

export function trackMealPlanUpdated(orderPlan: any) {
  const products = _.map(orderPlan.lineItems, (lineItem) =>
    formatProductType({ product: lineItem.product, lineItem, orderPlan }),
  );
  const payload = {
    products,
  };
  trackEvent(TrackerEvent.MealPlanUpdated, payload || {});
}

export function trackOrderPlaced(
  order: any,
  productsList: any,
  cartId: string,
  is_returning: boolean,
  order_count: number,
) {
  const payload = formatOrderData(
    order,
    productsList,
    cartId,
    is_returning,
    order_count,
  );
  trackEvent(TrackerEvent.OrderPlaced, payload || {});
}

export function trackOrderFailed(
  cart: any,
  error_message: string,
  productsList: any,
) {
  const cartData = formatOrderFailedData(cart, productsList);
  const payload = {
    error_message,
    ...cartData,
  };
  trackEvent(TrackerEvent.OrderFailed, payload || {});
}

export function trackProductAdded(
  product: any,
  location: string,
  cartId: string,
) {
  const payload = formatProductData(product, location, cartId);
  trackEvent(TrackerEvent.ProductAdded, payload || {});
}

export function trackProductClicked(productData: any, location: string) {
  const product = formatLineItem(productData);
  const payload = {
    ...product,
    source: location,
  };
  trackEvent(TrackerEvent.ProductClicked, payload || {});
}

export function trackProductLiked(productData: any) {
  const payload = formatLineItem(productData);
  trackEvent(TrackerEvent.ProductLiked, payload || {});
}

export function trackProductListFiltered(
  filter: any,
  products: any,
  sortValue: string,
  selectedFilters: Record<string, unknown>,
) {
  const parsedSorts = JSON.parse(sortValue);
  const { productWhereInput } = filter;
  const sorts = _.map(
    Array.isArray(parsedSorts) ? parsedSorts : [parsedSorts],
    ({ fieldName, sortDirection }) => ({
      type: fieldName,
      value: sortDirection,
    }),
  );
  const menuHierarchy = productWhereInput.menuHierarchy._eq || '';
  const filters = formatFilters(selectedFilters);
  const productList = _.map(products, (product) => product.sku);
  const formattedProducts = _.map(products, (product) =>
    formatProductType({ product, productList }, true),
  );
  const payload = {
    sorts,
    filters,
    list_id: menuHierarchy,
    list_name: menuHierarchy,
    products: formattedProducts,
  };
  trackEvent(TrackerEvent.ProductListFiltered, payload || {});
}

export function trackProductListViewed(filter: any, products: any) {
  const { productWhereInput } = filter;
  const menuHierarchy = productWhereInput.menuHierarchy._eq || '';
  const productList = _.map(products, (product) => product.sku);
  const formattedProducts = _.map(products, (product) =>
    formatProductType({ product, productList }, true),
  );
  const payload = {
    list_id: menuHierarchy,
    list_name: menuHierarchy,
    products: formattedProducts,
  };

  trackEvent(TrackerEvent.ProductListViewed, payload || {});
}

export function trackProductRemoved(
  product: any,
  location: string,
  cartId: string,
) {
  const payload = formatProductData(product, location, cartId);
  trackEvent(TrackerEvent.ProductRemoved, payload || {});
}

export function trackProductUnliked(productData: any) {
  const payload = formatLineItem(productData);
  trackEvent(TrackerEvent.ProductUnliked, payload || {});
}

export function trackProductViewed(product: any) {
  const payload = formatLineItem(product);
  trackEvent(TrackerEvent.ProductViewed, payload || {});
}

export function trackMealPlanGenerated(plan: any) {
  const products = _.map(plan.lineItems, (lineItem) =>
    formatProductType({ product: lineItem.product, lineItem }),
  );
  const payload = {
    products,
  };
  trackEvent(TrackerEvent.MealPlanGenerated, payload || {});
}

export function trackRecommendationViewed(
  promotionId: any,
  name: string,
  position: string,
) {
  const payload = {
    promotion_id: promotionId,
    name,
    position,
  };
  trackEvent(TrackerEvent.RecommendationViewed, payload || {});
}

export function trackIdentify(
  mongoId: string,
  customer: any,
  subscription: any,
) {
  let formattedAddress;
  let preferredAddress =
    customer.addresses.find((address: any) => address.preferred) ||
    customer.addresses[0];
  if (!preferredAddress) {
    preferredAddress = undefined;
  } else {
    formattedAddress = formatAddressType(preferredAddress);
  }
  const payload = {
    id: mongoId,
    email: customer?.email,
    first_name: customer?.firstName,
    last_name: customer?.lastName,
    phone: preferredAddress?.phone || undefined,
    created_at: customer?.createdAt,
    order_count: customer?.numberOfOrders || 0,
    address: formattedAddress || undefined,
    is_returning: customer?.numberOfOrders > 0,
    subscription_frequency: subscription?.frequency || undefined,
    subscription_value:
      subscription?.currentOrder?.totals?.orderTotal || undefined,
    subscription_status: subscription?.status || undefined,
  };
  trackEvent(TrackerEvent.Identify, payload || {});
}

export function trackSignedOut() {
  trackEvent(TrackerEvent.SignedOut, {});
}

export function trackReferralCodeShared(referral_code: string) {
  const payload = {
    referral_code,
  };
  trackEvent(TrackerEvent.ReferralCodeShared, payload || {});
}

export function trackSubscriptionCancelled(
  subscription: any,
  productList: any,
  reason: string,
) {
  const formattedSubscription = formatSubscription(subscription, productList);
  const payload = {
    ...formattedSubscription,
    reason,
  };
  trackEvent(TrackerEvent.SubscriptionCancelled, payload || {});
}

export function trackSubscriptionStarted(subscription: any, productList: any) {
  const payload = formatSubscription(subscription, productList);
  trackEvent(TrackerEvent.SubscriptionStarted, payload || {});
}

export function trackMealPlanAddedToCart(
  mealPlan: any,
  isUpdate: boolean,
  productsList: any,
) {
  const { products } = formatCartData(mealPlan, productsList);
  const payload = {
    products,
    is_update: isUpdate,
  };
  trackEvent(TrackerEvent.MealPlanAddedToCart, payload || {});
}

export function trackMealPlanRemovedFromCart(mealPlan: any) {
  const products = _.map(mealPlan.lineItems, (lineItem) =>
    formatProductType({ product: lineItem.product, lineItem, mealPlan }),
  );
  const payload = {
    products,
  };
  trackEvent(TrackerEvent.MealPlanRemovedFromCart, payload || {});
}

export function trackDeliverySkipped(subscription: any, productList: any) {
  const payload = formatSubscription(subscription, productList);
  trackEvent(TrackerEvent.DeliverySkipped, payload || {});
}

export function trackDeliveryUnskipped(subscription: any, productList: any) {
  const payload = formatSubscription(subscription, productList);
  trackEvent(TrackerEvent.DeliveryUnskipped, payload || {});
}

export function trackSubscriptionUpdated(subscription: any, productList: any) {
  const payload = formatSubscription(subscription, productList);
  trackEvent(TrackerEvent.SubscriptionUpdated, payload || {});
}

export function trackFormViewed(formName: string) {
  trackEvent(TrackerEvent.FormViewed, {
    form_name: formName,
  });
}

export function trackFormSubmitted(formName: string) {
  trackEvent(TrackerEvent.FormSubmitted, {
    form_name: formName,
  });
}

export function trackPromotionClicked(creative: string, toggle_status: string) {
  trackEvent(TrackerEvent.PromotionClicked, { creative, toggle_status });
}
