// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum ProductSubCategory {
  BREAKFAST_BUNDLES="BREAKFAST_BUNDLES",
CRISP_BARS="CRISP_BARS",
ENERGY_DRINKS="ENERGY_DRINKS",
EVERY_BODY="EVERY_BODY",
FAVA_BEANS="FAVA_BEANS",
HIGH_PROTEIN="HIGH_PROTEIN",
HOT_BREAKFAST="HOT_BREAKFAST",
LOW_CARB_BARS="LOW_CARB_BARS",
LO_CAL="LO_CAL",
MEAL_BUNDLES="MEAL_BUNDLES",
OAT_SLICES="OAT_SLICES",
PLANT_BASED_COOKIES="PLANT_BASED_COOKIES",
PLANT_BASED_SMOOTHIES="PLANT_BASED_SMOOTHIES",
PLUS="PLUS",
PROTEIN_BALLS="PROTEIN_BALLS",
PROTEIN_BITES="PROTEIN_BITES",
PROTEIN_COOKIES="PROTEIN_COOKIES",
PROTEIN_MUFFLINS="PROTEIN_MUFFLINS",
PROTEIN_MUG_CAKES="PROTEIN_MUG_CAKES",
PROTEIN_OATS="PROTEIN_OATS",
PROTEIN_POWDERS="PROTEIN_POWDERS",
PROTEIN_PUDDINGS="PROTEIN_PUDDINGS",
PROTEIN_SHAKES="PROTEIN_SHAKES",
PROTEIN_WATER="PROTEIN_WATER",
PROTEIN_WATER_POWDERS="PROTEIN_WATER_POWDERS",
PROTEIN_YOGHURT="PROTEIN_YOGHURT",
RECOVERY_BARS="RECOVERY_BARS",
REGULAR_MEALS="REGULAR_MEALS",
SALADS="SALADS",
SAVOURY_SNACKS="SAVOURY_SNACKS",
SHRED_PROTEIN_POWDERS="SHRED_PROTEIN_POWDERS",
SNACK_BUNDLES="SNACK_BUNDLES",
SOUPS="SOUPS",
SOUP_BUNDLES="SOUP_BUNDLES",
WHEY_PROTEIN_POWDERS="WHEY_PROTEIN_POWDERS",
WLP_BUNDLES="WLP_BUNDLES",
WLP_SNACKS="WLP_SNACKS"
}

/**
* ProductSubCategory
*/
export const ProductSubCategoryEnumType = types.enumeration("ProductSubCategory", [
        "BREAKFAST_BUNDLES",
  "CRISP_BARS",
  "ENERGY_DRINKS",
  "EVERY_BODY",
  "FAVA_BEANS",
  "HIGH_PROTEIN",
  "HOT_BREAKFAST",
  "LOW_CARB_BARS",
  "LO_CAL",
  "MEAL_BUNDLES",
  "OAT_SLICES",
  "PLANT_BASED_COOKIES",
  "PLANT_BASED_SMOOTHIES",
  "PLUS",
  "PROTEIN_BALLS",
  "PROTEIN_BITES",
  "PROTEIN_COOKIES",
  "PROTEIN_MUFFLINS",
  "PROTEIN_MUG_CAKES",
  "PROTEIN_OATS",
  "PROTEIN_POWDERS",
  "PROTEIN_PUDDINGS",
  "PROTEIN_SHAKES",
  "PROTEIN_WATER",
  "PROTEIN_WATER_POWDERS",
  "PROTEIN_YOGHURT",
  "RECOVERY_BARS",
  "REGULAR_MEALS",
  "SALADS",
  "SAVOURY_SNACKS",
  "SHRED_PROTEIN_POWDERS",
  "SNACK_BUNDLES",
  "SOUPS",
  "SOUP_BUNDLES",
  "WHEY_PROTEIN_POWDERS",
  "WLP_BUNDLES",
  "WLP_SNACKS",
      ])
