// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { IObservableArray } from "mobx"
import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { FileFieldModel, FileFieldModelType } from "./FileFieldModel"
import { FileFieldModelSelector } from "./FileFieldModel.base"
import { ItemStatusEnumType } from "./ItemStatusEnum"
import { MarketingContentApprovalWorkflowStageEnumType } from "./MarketingContentApprovalWorkflowStageEnum"
import { MenuHierarchyModelSectionsFieldModelSelector } from "./MenuHierarchyModelSectionsFieldModelSelector"
import { MenuHierarchyModelSeoBlurbFieldModel, MenuHierarchyModelSeoBlurbFieldModelType } from "./MenuHierarchyModelSeoBlurbFieldModel"
import { MenuHierarchyModelSeoBlurbFieldModelSelector } from "./MenuHierarchyModelSeoBlurbFieldModel.base"
import { MenuHierarchyRecordModel, MenuHierarchyRecordModelType } from "./MenuHierarchyRecordModel"
import { MenuSortOptionRecordModel, MenuSortOptionRecordModelType } from "./MenuSortOptionRecordModel"
import { MenuSortOptionRecordModelSelector } from "./MenuSortOptionRecordModel.base"
import { PagePathRecordModel, PagePathRecordModelType } from "./PagePathRecordModel"
import { PagePathRecordModelSelector } from "./PagePathRecordModel.base"
import { ProductBenefitSectionRecordModel, ProductBenefitSectionRecordModelType } from "./ProductBenefitSectionRecordModel"
import { ProductBenefitSectionRecordModelSelector } from "./ProductBenefitSectionRecordModel.base"
import { RangeDescriptionSectionRecordModel, RangeDescriptionSectionRecordModelType } from "./RangeDescriptionSectionRecordModel"
import { RangeDescriptionSectionRecordModelSelector } from "./RangeDescriptionSectionRecordModel.base"
import { SiteLocale } from "./SiteLocaleEnum"
import { TagModel, TagModelType } from "./TagModel"
import { TagModelSelector } from "./TagModel.base"
import { RootStoreType } from "./index"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  childrenCategories: IObservableArray<MenuHierarchyRecordModelType>;
}

/**
 * MenuHierarchyRecordBase
 * auto generated base class for the model MenuHierarchyRecordModel.
 *
 * Record of type Menu Hierarchy (menu_hierarchy)
 */
export const MenuHierarchyRecordModelBase = withTypedRefs<Refs>()(ModelBase
  .named('MenuHierarchyRecord')
  .props({
    __typename: types.optional(types.literal("MenuHierarchyRecord"), "MenuHierarchyRecord"),
    _createdAt: types.union(types.undefined, types.frozen()),
    /** Editing URL */
    _editingUrl: types.union(types.undefined, types.null, types.string),
    _firstPublishedAt: types.union(types.undefined, types.null, types.frozen()),
    _isValid: types.union(types.undefined, types.frozen()),
    _modelApiKey: types.union(types.undefined, types.string),
    _publicationScheduledAt: types.union(types.undefined, types.null, types.frozen()),
    _publishedAt: types.union(types.undefined, types.null, types.frozen()),
    /** Generates SEO and Social card meta tags to be used in your frontend */
    _seoMetaTags: types.union(types.undefined, types.array(types.late((): any => TagModel))),
    _stage: types.union(types.undefined, MarketingContentApprovalWorkflowStageEnumType),
    _status: types.union(types.undefined, ItemStatusEnumType),
    _unpublishingScheduledAt: types.union(types.undefined, types.null, types.frozen()),
    _updatedAt: types.union(types.undefined, types.frozen()),
    active: types.union(types.undefined, types.null, types.frozen()),
    category: types.union(types.undefined, types.null, types.string),
    childrenCategories: types.union(types.undefined, types.array(types.late((): any => MenuHierarchyRecordModel))),
    createdAt: types.union(types.undefined, types.frozen()),
    defaultSortOption: types.union(types.undefined, types.null, types.late((): any => MenuSortOptionRecordModel)),
    description: types.union(types.undefined, types.null, types.string),
    extraLabel: types.union(types.undefined, types.null, types.string),
    id: types.union(types.undefined, types.frozen()),
    image: types.union(types.undefined, types.null, types.late((): any => FileFieldModel)),
    key: types.union(types.undefined, types.null, types.string),
    name: types.union(types.undefined, types.null, types.string),
    ogImage: types.union(types.undefined, types.null, types.late((): any => FileFieldModel)),
    pagePaths: types.union(types.undefined, types.array(types.late((): any => PagePathRecordModel))),
    position: types.union(types.undefined, types.null, types.frozen()),
    productWhereInput: types.union(types.undefined, types.null, types.frozen()),
    sections: types.union(types.undefined, types.array(types.union(types.late((): any => ProductBenefitSectionRecordModel), types.late((): any => RangeDescriptionSectionRecordModel)))),
    seoBlurb: types.union(types.undefined, types.null, types.late((): any => MenuHierarchyModelSeoBlurbFieldModel)),
    seoDescription: types.union(types.undefined, types.null, types.string),
    seoTitle: types.union(types.undefined, types.null, types.string),
    showBulkDiscountBar: types.union(types.undefined, types.null, types.frozen()),
    slug: types.union(types.undefined, types.null, types.string),
    updatedAt: types.union(types.undefined, types.frozen()),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  })))

export class MenuHierarchyRecordModelSelector extends QueryBuilder {
  get _createdAt() { return this.__attr(`_createdAt`) }
  get _editingUrl() { return this.__attr(`_editingUrl`) }
  get _firstPublishedAt() { return this.__attr(`_firstPublishedAt`) }
  get _isValid() { return this.__attr(`_isValid`) }
  get _modelApiKey() { return this.__attr(`_modelApiKey`) }
  get _publicationScheduledAt() { return this.__attr(`_publicationScheduledAt`) }
  get _publishedAt() { return this.__attr(`_publishedAt`) }
  get _stage() { return this.__attr(`_stage`) }
  get _status() { return this.__attr(`_status`) }
  get _unpublishingScheduledAt() { return this.__attr(`_unpublishingScheduledAt`) }
  get _updatedAt() { return this.__attr(`_updatedAt`) }
  get active() { return this.__attr(`active`) }
  get category() { return this.__attr(`category`) }
  get createdAt() { return this.__attr(`createdAt`) }
  get description() { return this.__attr(`description`) }
  get extraLabel() { return this.__attr(`extraLabel`) }
  get id() { return this.__attr(`id`) }
  get key() { return this.__attr(`key`) }
  get name() { return this.__attr(`name`) }
  get position() { return this.__attr(`position`) }
  get productWhereInput() { return this.__attr(`productWhereInput`) }
  get seoDescription() { return this.__attr(`seoDescription`) }
  get seoTitle() { return this.__attr(`seoTitle`) }
  get showBulkDiscountBar() { return this.__attr(`showBulkDiscountBar`) }
  get slug() { return this.__attr(`slug`) }
  get updatedAt() { return this.__attr(`updatedAt`) }
  _seoMetaTags(builder: string | TagModelSelector | ((selector: TagModelSelector) => TagModelSelector) | undefined, args?: { locale?: (SiteLocale | null) }) { return this.__child(`_seoMetaTags`+ (args ? '('+['locale'].map((argName) => ((args as any)[argName] ? `${argName}: ${JSON.stringify((args as any)[argName])}` : null) ).filter((v) => v!=null).join(', ') + ')': ''), TagModelSelector, builder) }
  childrenCategories(builder: string | MenuHierarchyRecordModelSelector | ((selector: MenuHierarchyRecordModelSelector) => MenuHierarchyRecordModelSelector) | undefined) { return this.__child(`childrenCategories`, MenuHierarchyRecordModelSelector, builder) }
  defaultSortOption(builder: string | MenuSortOptionRecordModelSelector | ((selector: MenuSortOptionRecordModelSelector) => MenuSortOptionRecordModelSelector) | undefined) { return this.__child(`defaultSortOption`, MenuSortOptionRecordModelSelector, builder) }
  image(builder: string | FileFieldModelSelector | ((selector: FileFieldModelSelector) => FileFieldModelSelector) | undefined) { return this.__child(`image`, FileFieldModelSelector, builder) }
  ogImage(builder: string | FileFieldModelSelector | ((selector: FileFieldModelSelector) => FileFieldModelSelector) | undefined) { return this.__child(`ogImage`, FileFieldModelSelector, builder) }
  pagePaths(builder: string | PagePathRecordModelSelector | ((selector: PagePathRecordModelSelector) => PagePathRecordModelSelector) | undefined) { return this.__child(`pagePaths`, PagePathRecordModelSelector, builder) }
  sections(builder: string | MenuHierarchyModelSectionsFieldModelSelector | ((selector: MenuHierarchyModelSectionsFieldModelSelector) => MenuHierarchyModelSectionsFieldModelSelector) | undefined) { return this.__child(`sections`, MenuHierarchyModelSectionsFieldModelSelector, builder) }
  seoBlurb(builder: string | MenuHierarchyModelSeoBlurbFieldModelSelector | ((selector: MenuHierarchyModelSeoBlurbFieldModelSelector) => MenuHierarchyModelSeoBlurbFieldModelSelector) | undefined) { return this.__child(`seoBlurb`, MenuHierarchyModelSeoBlurbFieldModelSelector, builder) }
}
export function selectFromMenuHierarchyRecord() {
  return new MenuHierarchyRecordModelSelector()
}

export const menuHierarchyRecordModelPrimitives = selectFromMenuHierarchyRecord()._createdAt._editingUrl._firstPublishedAt._isValid._modelApiKey._publicationScheduledAt._publishedAt._stage._status._unpublishingScheduledAt._updatedAt.active.category.createdAt.description.extraLabel.key.name.position.productWhereInput.seoDescription.seoTitle.showBulkDiscountBar.slug.updatedAt
